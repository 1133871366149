import React from 'react';
import logo from "../../images/logo_trasp.png";
const MaintenancePage = () => {
  return (
<div class="bg-gray-100">
  <div class="min-h-screen flex flex-col justify-center items-center">
    <img src={logo} alt="Logo" class="mb-8 h-40" />
    <h1 class="text-4xl md:text-5xl lg:text-6xl font-bold text-center text-gray-700 mb-4">Il sito è in manutenzione</h1>
    <p class="text-center text-gray-500 text-lg md:text-xl lg:text-2xl mb-8">Stiamo lavorando duramente per migliorare l'esperienza utente. Restate sintonizzati!</p>
  </div>
</div>
  );
};

export default MaintenancePage;
