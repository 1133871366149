import { TrashIcon, PencilIcon } from "@heroicons/react/outline";
import RowTable from "../../custom/RowTable";
import CellRowTable from "../../custom/CellRowTable";
import ButtonTable from "../../custom/ButtonTable";
import { CheckCircleIcon } from "@heroicons/react/solid";
export const RowShippingPriceList = ({
  shippingPriceList,
  setShippingPriceListToEdit,
  setShippingPriceListToDelete,
  setConfirmationMessage,
  setShowConfirmationMessage,
  setOpenEditShippingPriceListPopup,
}) => {
  return (
    <RowTable>
      <CellRowTable>{shippingPriceList.code}</CellRowTable>
      <CellRowTable>
        <div className="flex justify-center">{shippingPriceList.isDefault ?? false ? <CheckCircleIcon className="h-5 w-5" /> : ""}</div>
      </CellRowTable>
      <CellRowTable>
        <div className="flex justify-center">{shippingPriceList.isPremium ?? false ? <CheckCircleIcon className="h-5 w-5" /> : ""}</div>
      </CellRowTable>
      <CellRowTable>
        <div className="flex gap-2">
          <ButtonTable
            Icon={PencilIcon}
            color={
              "from-green-400 via-green-500 to-green-600 focus:ring-green-300 dark:focus:ring-green-800"
            }
            onClick={() => {
              setShippingPriceListToEdit(shippingPriceList);
              setOpenEditShippingPriceListPopup(true);
            }}
          />
          <ButtonTable
            Icon={TrashIcon}
            color={
              "from-red-400 via-red-500 to-red-600 focus:ring-red-300 dark:focus:ring-red-800"
            }
            onClick={() => {
              setConfirmationMessage(
                `Sei sicuro di voler cancellare il listino prezzi spedizione col codice '${shippingPriceList.code}'?`
              );
              setShippingPriceListToDelete(shippingPriceList);
              setShowConfirmationMessage(true);
            }}
          />
        </div>
      </CellRowTable>
    </RowTable>
  );
};
