import { userApi } from "./userApi";

const shipmentStatusApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    createShipmentStatus: builder.mutation({
      query: (newStatus) => ({
        url: `shipment-status`,
        method: "POST",
        body: newStatus,
      }),
      invalidatesTags: ["ShipmentStatus"],
    }),
    getShipmentStatusById: builder.query({
      query: (id) => `shipment-status/${id}`,
      providesTags: ["ShipmentStatus"],
    }),
    getAllShipmentStatuses: builder.query({
        query: ({ status, spedisciOnlineStatus }) => {
            const queryParams = new URLSearchParams();
            if (status) queryParams.append('status', status);
            if (spedisciOnlineStatus) queryParams.append('spedisciOnlineStatus', spedisciOnlineStatus);
            return {
                url: `shipment-status?${queryParams.toString()}`,
            };
            },
            providesTags: ["ShipmentStatus"],
    }),
    updateShipmentStatus: builder.mutation({
        query: ({ ids, spedisciOnlineStatus }) => ({
          url: `shipment-status`,
          method: "PUT",
          body: { ids, spedisciOnlineStatus },
        }),
        invalidatesTags: ["ShipmentStatus"],
      }),
    deleteShipmentStatus: builder.mutation({
      query: (id) => ({
        url: `shipment-status/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ShipmentStatus"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateShipmentStatusMutation,
  useGetShipmentStatusByIdQuery,
  useGetAllShipmentStatusesQuery,
  useUpdateShipmentStatusMutation,
  useDeleteShipmentStatusMutation,
} = shipmentStatusApi;