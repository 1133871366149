import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef, useState } from "react";
import { useUpdateShippingCostMutation } from "../../services/shipmentApi";

export const PopupEditShipmentCost = ({ open, setOpen }) => {
  const cancelButtonRef = useRef(null);
  const [trackingNumber, setTrackingNumber] = useState("");
  const [shippingCost, setShippingCost] = useState("");

  const [updateShippingCost, result] = useUpdateShippingCostMutation();

  const handleEditButton = async (e) => {
    e.preventDefault();
    await updateShippingCost({ trackingNumber, shippingCost })
      .unwrap()
      .finally(() => setOpen(false)); //.unwrap();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Modifica Costo Spedizione
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="flex-1 ">
                        <div className="flex items-center justify-between">
                          <label
                            htmlFor="shipping-cost-new-shipping-price-list"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Numero Tracciamento
                          </label>
                          <div className="text-sm"></div>
                        </div>
                        <div className="mt-2">
                          <input
                            onChange={(e) => setTrackingNumber(e.target.value)}
                            id="shipping-cost-new-shipping-price-list"
                            name="shipping-cost-new-shipping-price-list"
                            type="text"
                            required
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-facile sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="flex-1 ">
                        <div className="flex items-center justify-between">
                          <label
                            htmlFor="shipping-cost-new-shipping-price-list"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Costo Spedizione
                          </label>
                          <div className="text-sm"></div>
                        </div>
                        <div className="mt-2">
                          <input
                            onChange={(e) => setShippingCost(e.target.value)}
                            id="shipping-cost-new-shipping-price-list"
                            name="shipping-cost-new-shipping-price-list"
                            type="text"
                            required
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-facile sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-green-facile px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-facile sm:col-start-2"
                    onClick={(e) => handleEditButton(e)}
                  >
                    Aggiorna
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Annulla
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
