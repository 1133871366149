import { ArrowDownIcon, ArrowsExpandIcon, ArrowSmDownIcon } from "@heroicons/react/solid"
import { imagesUrl } from "../../config/baseUrl"
import { ArrowNarrowDownIcon, ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline"
import { useEffect, useState } from "react"
import { Tooltip } from "@mui/material"

export const CardCustomerProduct = ({ fullName, products, defaultOpen }) => {
    const [ openCard, setOpenCard ] = useState(false)

    useEffect(() => {
      setOpenCard(defaultOpen)
    }, [defaultOpen])

    return (
        <div key={fullName}
                  className="border-b border-t border-gray-200 bg-white shadow-sm sm:rounded-lg sm:border"
                >
                  <div className="flex justify-between border-b border-gray-200 p-4 cursor-pointer" onClick={() => setOpenCard(!openCard)}>
                    <div className="flex gap-1">
                    <h4>{fullName}</h4>
                    <Tooltip title="indica il numero di prodotti diversi">
                      <span className="bg-green-500 rounded-full px-2 font-bold text-white">{products.length}</span>
                    </Tooltip>
                    </div>
                    <div className="cursor-pointer" onClick={() => setOpenCard(!openCard)}>
                      {openCard ? <ChevronUpIcon className="h-6 w-6" /> : <ChevronDownIcon className="h-6 w-6" />}                   
                    </div>
                  </div>

                  {openCard ? <ul role="list" className="divide-y divide-gray-200">
                    {products.map((product) => (
                      <li key={product.sku} className="p-4 sm:p-6">
                        <div className="flex items-center sm:items-start">
                          <div className="size-20 shrink-0 overflow-hidden rounded-lg bg-gray-200 sm:size-20">
                            <img src={`${imagesUrl}${product.product_image}`} className="size-full object-cover" />
                          </div>
                          <div className="ml-6 flex-1 text-sm">
                            <div className="font-medium text-gray-900 mb-2 sm:flex sm:justify-between">
                              <h5>{product.name}</h5>
                            </div>
                            <div className="flex items-center gap-2 w-full">
                              <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                    {`${product.quantity} in magazzino`}
                                </span>
                              {product.paid_to_ship > 0 ? 
                                <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20">
                                    {`di cui ${product.paid_to_ship} ${product.paid_to_ship > 1 ? "pagati" : "pagato"} da spedire`}
                                </span> : <></>}
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul> : <></>}
                  
                </div>
    )
}