import { useEffect, useState } from "react";
import { useGetHoldByShipmentIdQuery, useUpdateShipmentOnHoldMutation } from "../../../services/shipmentOnHoldApi";
import useHandleMutationResponse from "../../../hooks/useHandleMutationResponse";
import PopupDialog from "../../../components/custom/PopupDialog";
import { Radio, RadioGroup } from "@headlessui/react";
import { HandlingStatusEnum } from "../../../enums/HandlingStatusEnum";
import { SaveIcon } from "@heroicons/react/outline";
import { ButtonGreen } from "../../../components/ButtonGreen";
import moment from "moment";
import { DatePickerBasic } from "../../../components_used/DatePickerBasic";
import TextCopyToClipboard from "../../../components/catalog/TextCopyToClipboard";

const handlingStatusRadioButtons = [
  {
    id: HandlingStatusEnum.IN_LAVORAZIONE.id,
    name: HandlingStatusEnum.IN_LAVORAZIONE.name
  },
  {
    id: HandlingStatusEnum.SVINCOLO_EFFETTUATO.id,
    name: HandlingStatusEnum.SVINCOLO_EFFETTUATO.name
  },
  {
    id: HandlingStatusEnum.SVINCOLO_NON_POSSIBILE.id,
    name: HandlingStatusEnum.SVINCOLO_NON_POSSIBILE.name
  }
]

export function ManageShipmentOnHoldPopupAdmin({ trackingNumber, open, setOpen, shipmentId, setMutationResponse }) {
  
    const [dataError, setDataError] = useState(undefined)
    const { data, isLoading, isSuccess, isError, error, fulfilledTimeStamp } = useGetHoldByShipmentIdQuery(shipmentId, {
      refetchOnMountOrArgChange: true,
      skip: !open,
    });
    
    const [
      updateShipmentHold,
      {
        isLoading: updatingShipmentHold,
        isSuccess: updatedShipmentHold,
        isError: errorUpdatingEvent,
        error: errorUpdatingText,
      },
    ] = useUpdateShipmentOnHoldMutation();
    
    const [shipmentOnHold, setShipmentOnHold] = useState({
      id: Number(),
      shipmentId: shipmentId,
      createdDate: "",
      nextAction: "",
      nextActionAddress: "",
      handlingStatusMotivation: "",
      nextActionNote: "",
      closed: false,
      handlingStatus: Number(),
      handlingCost: Number(),
      handlingDate: ""
    })
  
    useHandleMutationResponse({
      successEvent: updatedShipmentHold,
      errorEvent: errorUpdatingEvent,
      successMessage: "Giacenza Gestita Con Successo",
      errorMessage: errorUpdatingText,
      setMutationResponse,
      dependencies: [updatingShipmentHold],
      onSuccess: () => setOpen(false)
    });
  
    useEffect(() => {
      if(isSuccess) setShipmentOnHold({ ...data, handlingDate: moment(data.handlingDate).format("yyyy-MM-DD")})
    }, [fulfilledTimeStamp])
  
    const handleConfirm = async () => {
      if(shipmentOnHold.handlingStatus === HandlingStatusEnum['In Lavorazione']){
        setDataError("Inserire uno stato di gestione diverso da 'In Lavorazione'")
        return;
      }
  
      await updateShipmentHold({ id: shipmentOnHold.id, shipmentOnHold})
    }
  
    return <PopupDialog open={open} setOpen={setOpen}>
      <div className="flex justify-center">
      <TextCopyToClipboard text={trackingNumber} textHover={"COPIA"} />
      </div>
      <span className="inline-flex items-center justify-center rounded-md bg-green-100 px-2 py-1 text-md font-medium text-green-800 w-full mb-2">
          <span>Richiesta <b>{shipmentOnHold.nextAction}</b> - {shipmentOnHold.handlingCost}€</span>
      </span>
      <DatePickerBasic label={"Data Svincolo"} value={shipmentOnHold.handlingDate} onChange={(e) => setShipmentOnHold({ ...shipmentOnHold, handlingDate: e.target.value})} disabled={true}/>
      <div className='mt-2'>
        <label>Nota Svincolo</label>
        <input
          // disabled={shipmentOnHold.handlingDate != null}
          className="block w-full rounded-xl border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
          type="textarea"
          value={shipmentOnHold.nextActionNote}
          disabled={true}
          onChange={(e) => setShipmentOnHold({ ...shipmentOnHold, nextActionNote: e.target.value})}
        />
      </div>
      {shipmentOnHold.nextActionAddress !== "" &&
        <div className='mt-2'>
          <label>Nuovo indirizzo di spedizione</label>
          <input
            // disabled={shipmentOnHold.handlingDate != null}
            className="block w-full rounded-xl border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            type="textarea"
            value={shipmentOnHold.nextActionAddress}
            onChange={(e) => setShipmentOnHold({ ...shipmentOnHold, nextActionAddress: e.target.value})}
          />
        </div>
      }
      <RadioGroup value={shipmentOnHold.handlingStatus} onChange={(value) => setShipmentOnHold({ ...shipmentOnHold, handlingStatus: value})} className="mt-2 flex gap-3 flex-wrap justify-around">
          {handlingStatusRadioButtons.map((handlingStatus) => (
            <Radio
              key={handlingStatus.id}
              value={handlingStatus.id}
              className={'flex items-center justify-center rounded-md bg-white px-3 py-3 text-sm font-semibold uppercase text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-green-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-green-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-green-500 sm:flex-1 [&:not([data-focus])]:[&:not([data-checked])]:ring-inset'}
            >
              {handlingStatus.name}
            </Radio>
          ))}
        </RadioGroup>
        <div className='mt-2'>
          <label>Motivazione (se necessaria)</label>
          <input
            // disabled={shipmentOnHold.handlingDate != null}
            className="block w-full rounded-xl border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            type="textarea"
            value={shipmentOnHold.handlingStatusMotivation}
            onChange={(e) => setShipmentOnHold({ ...shipmentOnHold, handlingStatusMotivation: e.target.value})}
          />
        </div>      
        {shipmentOnHold.handlingStatus !== HandlingStatusEnum.IN_LAVORAZIONE.id &&
          <section id='new-address' className='mt-2 flex justify-end gap-2 flex-wrap'>
            <input
              // disabled={shipmentOnHold.handlingDate != null}
              className="flex-1 block w-full rounded-xl border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
              type="number"
              value={shipmentOnHold.handlingCost == 0 ? "" : shipmentOnHold.handlingCost}
              onChange={(e) => setShipmentOnHold({ ...shipmentOnHold, handlingCost: Number(e.target.value)})}
            />
            <ButtonGreen isLoading={updatingShipmentHold} CustomIcon={SaveIcon} onClick={() => handleConfirm()} text={"Aggiorna Giacenza"} />
          </section>
        }
        {dataError &&
          <span className="inline-flex items-center justify-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700 w-full text-center mt-1">
            {dataError}
          </span>
        }      
    </PopupDialog>
    
  }