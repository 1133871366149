import React, { useEffect, useState } from "react";
import { useGetAllMutation, useGetAllQuery } from "../services/cashFlowApi";
import { LoadingIcon } from "./custom/LoadingIcon";
import RowCashFlow from "./RowCashFlow";
import { PopupEditShipmentCost } from "./shipment/PopupEditShipmentCost";
import { TableLoading } from "./shipment/LoadingTable";
import { useSelector } from "react-redux";
import { MyDateTime } from "../functions/date_time";
import moment from "moment";
import "moment/locale/it";
import { TrackingText } from "./order/TrackingButton";
import { TransactionTypeEnum } from "../enums/TransactionTypeEnum";
import { DocumentIcon } from "@heroicons/react/outline";
import { exportToExcelBlob } from "../functions/export_to_excel";
import { PaginationList } from "./catalog/PaginationList";
import { useGenerateCashFlowExcelMutation } from "../services/excelApi";
import axios from "axios";
import { baseUrl } from "../config/baseUrl";
moment.locale("it");
function ListCashFlow({ startDate, endDate, customer, filterType }) {
  const [pagination, setPagination] = useState({
    page: 1,
    rowsPage: 20
  })
  const [getAll, { data: movements, isLoading, isSuccess, isError, error }] =
    useGetAllMutation();
  const [generateCashFlowExcel, { data: cashFlowExcelFile, isLoading: generatingCashFlowExcel, isSuccess: generatedCashFlowExcel, isError: isErrorGeneratingCashFlowExcel, error: errorGeneratingCashFlowExcel }] = useGenerateCashFlowExcelMutation()

  const auth = useSelector((state) => state.auth);
  const [exportingExcelProducts, setExportingExcelProducts] = useState(false);
  const [openEditShippingCostPopup, setOpenEditShippingCostPopup] =
    useState(false);

  useEffect(() => {
    getAll(
      { filter: { startDate, endDate, customer: customer, filterType, ...pagination } },
      { refetchOnMountOrArgChange: true }
    ).unwrap();

    
  }, [startDate, endDate, customer, filterType, pagination]);

  useEffect(() => {
    if(generatedCashFlowExcel){
      
    }
    if(isErrorGeneratingCashFlowExcel){
      
    }
  }, [generatingCashFlowExcel])

  if (isLoading) return <TableLoading />;

  const onClickExportExcel = async () => {
    setExportingExcelProducts(true);
    const response = await axios.post(
      `${baseUrl}excel/cashflow`,
      { filter: { startDate, endDate, customer: customer, filterType } },
      {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      }
    );

    if (response.status == 200) {
      const blob = await response.data;
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `lista_transazioni_${moment().format("YYYY_MM_DD_HH_mm_ss")}.xlsx`; // Nome del file da scaricare
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Errore nel download del file");
    }
    setExportingExcelProducts(false);
  };


  return (
    <>
      <PopupEditShipmentCost
        open={openEditShippingCostPopup}
        setOpen={setOpenEditShippingCostPopup}
      />

      <div className="mb-2 w-full  flex justify-between ">
        {auth.roles.includes(1) && (
          <button
            onClick={() => setOpenEditShippingCostPopup(true)}
            type="button"
            className=" text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
          >
            Aggiorna Costo Spedizione
          </button>
        )}
        <button
          onClick={() => onClickExportExcel()}
          type="button"
          className="bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center gap-x-1.5 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
        >
          Esporta XLSX
          {exportingExcelProducts ? (
            <LoadingIcon size={"h-4 w-4"} color={"text-white"} />
          ) : (
            <DocumentIcon className="h-4 w-4" />
          )}
        </button>
      </div>

      <div>
        <PaginationList numOrders={movements?.totalCount ?? 0} pagination={pagination} setPagination={setPagination}/>
        {isSuccess ? (
          movements?.items?.map((movement, index) => (
            <CardCashFlow key={index} movement={movement} />
          ))
        ) : isError ? (
          <span>{error.data}</span>
        ) : (
          <></>
        )}
        {/* </tbody>
        </table> */}
      </div>
    </>
  );
}

const CardCashFlow = ({ movement }) => {
  const auth = useSelector((state) => state.auth);

  return (
    <div className="bg-white shadow border border-gray-200 rounded-xl p-6 mb-6 transition-transform transform  flex-col justify-between items-center w-full z-50">
      <div className="flex justify-between items-center ">
        <div className="flex justify-between items-center">
          <div className="flex gap-2 items-center flex-wrap">
            {movement.transaction_type == TransactionTypeEnum.Ricarica ||
            movement.transaction_type == TransactionTypeEnum.Bonifico ? (
              <></>
            ) : (
              <span className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-500 ring-1 ring-inset ring-gray-400/20">
                {movement?.order_number}
              </span>
            )}
            {movement?.store_name && (
              <div className="text-sm text-gray-500">
                {movement?.store_name}
              </div>
            )}
          </div>
        </div>
        <div className="text-sm font-medium text-gray-900 ">
          {moment(movement?.created_date).format("DD MMM YYYY")}
        </div>
      </div>

      <div className="flex justify-between items-center my-2">
        {movement?.transaction_type == TransactionTypeEnum.Prodotti ? (
          <div className="text-lg text-gray-900 font-bold">
            {movement?.product_name}
          </div>
        ) : (
          <div className="text-lg text-gray-900 font-bold">
            {movement?.transaction_type}
          </div>
        )}

        <div
          className={`text-lg font-bold ${
            movement?.total_amount >= 0 ? "text-green-600" : "text-red-600"
          }`}
        >
          {movement?.total_amount !== 0 ? (
            movement?.total_amount >= 0 ? (
              `+${movement?.total_amount?.toFixed(2) ?? 0}€${
                movement?.transaction_type == TransactionTypeEnum.Prodotti
                  ? ` (x${movement.qty})`
                  : ""
              }`
            ) : (
              `${movement?.total_amount?.toFixed(2) ?? 0}€${
                movement?.transaction_type == TransactionTypeEnum.Prodotti
                  ? ` (x${movement.qty})`
                  : ""
              }`
            )
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className="flex justify-between items-center">
        <div className="text-sm text-gray-500">
          <TrackingText
            text={movement?.tracking_number ?? ""}
            trackingNumber={movement?.tracking_number ?? ""}
          />
        </div>
        
          {auth.roles.includes(1)
            ? movement.full_name === "" ? <></> : <div className="text-sm bg-green-600 px-2 py-1 rounded-md text-white font-bold">{movement.full_name}</div>
            : movement.customer_to_ship === "" ? <></> : <div className="text-sm bg-green-600 px-2 py-1 rounded-md text-white font-bold">{movement.customer_to_ship}</div>
          }
      </div>
    </div>
  );
};

export default ListCashFlow;
