import React, { useEffect, useState } from "react";
import { useGetAllCustomerProductInventoryQuery } from "../../services/customerProductInventoryApi";
import { LoadingIcon } from "../../components/custom/LoadingIcon";
import CustomerProductList from "../../components/customer-product-inventory/CustomerProductList";
import ToggleButton from "../../components/custom/ToggleButton";
import { PlusIcon, SearchIcon } from "@heroicons/react/solid";
import { InputField } from "../../components_used/InputField";
import { useSelector } from "react-redux";
import { debounce } from "@mui/material";
import { ButtonGreen } from "../../components/ButtonGreen";
import AddCustomerProductInventoryPopup from "./components/AddCustomerProductInventoryPopup";

function CustomerProductInventoryPage() {
  const auth = useSelector((state) => state.auth)
  const [openAddProductInventoryPopup, setOpenAddProductInventoryPopup] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [productsResearch, setProductResearch] = useState(false)
  const [expandAll, setExpandAll] = useState(false)
  const {
    data: customerProducts,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllCustomerProductInventoryQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

    // Filtraggio dei prodotti/clienti
    const filteredProducts = auth.roles.find((r) => r == 1) ? customerProducts?.filter((product) =>
      productsResearch
        ? product.products.find((p) => p.name.toLowerCase().includes(searchText.toLowerCase())) // Ricerca per Prodotto
        : product.fullname.toLowerCase().includes(searchText.toLowerCase()) // Ricerca per Cliente
    ) : customerProducts;

  return (
    <main className="px-4">
      {auth?.roles.find((r) => r == 1) ? <section id="filter-product-inventory" >
        <div className="flex w-full gap-2 my-2">
        <InputField placeholder={productsResearch ? "Ricerca Per Prodotto" : "Ricerca Per Cliente"} onChange={debounce((e) => setSearchText(e.target.value), 300)}/>
        <button
            onClick={() => {}}
            type="button"
            className="rounded-xl bg-green-600 p-2 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600
           bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium text-sm text-center"
          >
            <SearchIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="flex gap-2">
          <ToggleButton
            enabled={productsResearch}
            onChangeEvent={(e) => {
              setProductResearch(e);
            }}
            text={productsResearch ? "Ricerca Per Prodotto" : "Ricerca Per Cliente"}
          />
          <ToggleButton
            enabled={expandAll}
            onChangeEvent={(e) => {
              setExpandAll(e);
            }}
            text={expandAll ? "Comprimi Tutto" : "Espandi Tutto"}
          />
        </div>
      </section> : <></>}      
      {isLoading ? (
        <LoadingIcon size={"h-6 w-6"} color={"text-black"} />
      ) : isSuccess ? (
        <CustomerProductList expandAll={expandAll} customerProducts={filteredProducts} />
      ) : (
        <></>
      )}
      <div className="w-full flex justify-center sticky bottom-2 mt-5">
        <ButtonGreen  onClick={() => setOpenAddProductInventoryPopup(!openAddProductInventoryPopup)} text={"Prodotto Ad Inventario"} CustomIcon={PlusIcon} />
      </div>
     {auth?.roles.find((r) => r == 1) && <AddCustomerProductInventoryPopup open={openAddProductInventoryPopup} setOpen={setOpenAddProductInventoryPopup} />}
    </main>
  );
}

export default CustomerProductInventoryPage;