import React, { useState } from "react";
import HeaderTable from "../../../components/custom/HeaderTable";
import HeaderColTable from "../../../components/custom/HeaderColTable";
import BodyTable from "../../../components/custom/BodyTable";
import CellRowTable from "../../../components/custom/CellRowTable";
import RowTable from "../../../components/custom/RowTable";
import TextCopyToClipboard from "../../../components/catalog/TextCopyToClipboard";
import { useSelector } from "react-redux";
import { TrackingButton } from "../../../components/order/TrackingButton";
import BadgeShipmentStatus from "../../../components/shipment/BadgeShipmentStatus";
import SimpleNotification from "../../../components/SimpleNotification";
import { ManageShipmentOnHoldButton } from "./ManageShipmentOnHoldButton";
import ProductsOrderTable from "../../../components/order/NewShopifyOrderList/ProductsOrderTable";
import { ManageShipmentOnHoldPopupUser } from "./ManageShipmentOnHoldPopupUser";
import { ManageShipmentOnHoldPopupAdmin } from "./ManageShipmentOnHoldPopupAdmin";



function ListShipmentOnHold({ shipmentOnHolds, showOrderProducts }) {
    
    const auth = useSelector((state) => state.auth); 
    const isAdmin = auth.roles.includes(1);
    const [openPopup, setOpenPopup] = useState({
        show: false,
        shipmentId: null,
        trackingNumber: null,
        shippingPriceCustomer: null
    })
    const [ mutationResponse, setMutationResponse ] = useState({
        message: "",
        show: false,
        success: false        
    })

    const showPopupToManage = (shipmentId, trackingNumber, shippingPriceCustomer) => {
        setOpenPopup({
            show: !openPopup.show,
            shipmentId,
            trackingNumber,
            shippingPriceCustomer: shippingPriceCustomer
        })
    }
    return (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <SimpleNotification message={mutationResponse.message} show={mutationResponse.show} setShow={() => setMutationResponse({...mutationResponse, show: false})} success={mutationResponse.success} />
            <table className="w-full text-sm text-left text-gray-500">
                <HeaderTable>
                    <tr>
                        <HeaderColTable>Data</HeaderColTable>
                        <HeaderColTable>Nr. Ordine</HeaderColTable>
                        <HeaderColTable>Tracking</HeaderColTable>
                        <HeaderColTable>Destinatario</HeaderColTable>
                        <HeaderColTable>COD</HeaderColTable>
                        <HeaderColTable>Svincolo</HeaderColTable>
                        <HeaderColTable>Stato</HeaderColTable>
                        <HeaderColTable className={"text-center max-w-14"}>Costo Spedizione</HeaderColTable>
                    </tr>
                </HeaderTable>
                <BodyTable>
                    {shipmentOnHolds?.map((shipment, index) => (
                        <RowTable key={index}>
                            <CellRowTable>{shipment.created_date}</CellRowTable>
                            <CellRowTable>
                                {shipment.order_number}<br/>{shipment.alias_store}
                            {showOrderProducts && <>
                                <br/>
                                <ProductsOrderTable showOrderProducts={showOrderProducts} orderId={shipment.order_id} />
                            </>}
                            </CellRowTable>
                            <CellRowTable><TextCopyToClipboard text={shipment.tracking_number} textHover={"COPIA"}/></CellRowTable>
                            <CellRowTable>{shipment.customer}<br/>{shipment.shipping_address}</CellRowTable>
                            <CellRowTable>{shipment.order_price}</CellRowTable>
                            <CellRowTable> 
                                <ManageShipmentOnHoldButton 
                                    nextAction={shipment.next_action}
                                    handlingCost={shipment.handling_cost}
                                    onClickButton={() => showPopupToManage(shipment.id, shipment.tracking_number, shipment.shipping_price_customer)}
                                /> 
                            </CellRowTable>
                            <CellRowTable className={"max-w-40"}> 
                                <div className="flex flex-col gap-1 ">
                                    <BadgeShipmentStatus status={shipment.status}/>
                                    <TrackingButton trackingNumber={shipment.tracking_number}/>
                                </div>
                            </CellRowTable>
                            <CellRowTable className={"text-center max-w-14"}>{isAdmin ? shipment.shipping_cost : shipment.shipping_price_customer}</CellRowTable>
                        </RowTable>
                    ))}
                </BodyTable>
            </table>
            <ManageShipmentOnHoldPopupUser trackingNumber={openPopup.trackingNumber} open={openPopup.show && !isAdmin} setOpen={(state) => setOpenPopup({ ...openPopup, show: state })} shippingPriceCustomer={openPopup.shippingPriceCustomer} shipmentId={openPopup.shipmentId} setMutationResponse={setMutationResponse} />
            <ManageShipmentOnHoldPopupAdmin trackingNumber={openPopup.trackingNumber} open={openPopup.show && isAdmin} setOpen={(state) => setOpenPopup({ ...openPopup, show: state })} shipmentId={openPopup.shipmentId} setMutationResponse={setMutationResponse}/>
        </div>
    );
}

export default ListShipmentOnHold;