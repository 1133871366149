import { useEffect, useState } from "react";

import AddShippingPriceListPopup from "./AddShippingPriceListPopup";
import { RowShippingPriceList } from "./RowShippingPriceList";
import ModalConfirmationRequest from "../../custom/ModalConfirmationRequest";
import SimpleNotification from "../../SimpleNotification";
import EditShippingPriceListPopup from "./EditShippingPriceListPopup";
import {
  useAddShippingPriceListMutation,
  useDeleteShippingPriceListMutation,
  useEditShippingPriceListMutation,
  useGetShippingPriceListQuery,
} from "../../../services/shippingPriceListApi";
import { TableLoading } from "../LoadingTable";
import ListTable from "../../custom/ListTable";
import BodyTable from "../../custom/BodyTable";
import HeaderTable from "../../custom/HeaderTable";
import HeaderColTable from "../../custom/HeaderColTable";
import HiddenHeaderColTable from "../../custom/HiddenHeaderColTable";
import RowTable from "../../custom/RowTable";

export const ListShippingPriceList = () => {
  const [shippingPriceListToEdit, setShippingPriceListToEdit] = useState({
    id: 0,
    code: "",
    description: "",
    shippingCost: "",
  });
  const [shippingPriceListToAdd, setShippingPriceListToAdd] = useState({
    id: 0,
    code: "",
    description: "",
    shippingCost: "",
  });
  const [shippingPriceListToDelete, setShippingPriceListToDelete] = useState();
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const [show, setShow] = useState(false);
  const [openAddShippingPriceListPopup, setOpenAddShippingPriceListPopup] =
    useState(false);
  const [openEditShippingPriceListPopup, setOpenEditShippingPriceListPopup] =
    useState(false);
  const {
    data: shippingPriceLists,
    isLoading: loadingShippingPriceList,
    isSuccess: loadedShippingPriceList,
    isError: errorLoadingShippingPriceList,
    error: errorResponseLoadingShippingPriceList,
  } = useGetShippingPriceListQuery();

  const [
    addShippingPriceList,
    {
      isLoading: addingShippingPriceList,
      isSuccess: addedShippingPriceList,
      isError: errorAddingShippingPriceList,
      error: errorResponseAddingShippingPriceList,
    },
  ] = useAddShippingPriceListMutation();

  const [
    editShippingPriceList,
    {
      isLoading: editingShippingPriceList,
      isSuccess: editedShippingPriceList,
      isError: errorEditingShippingPriceList,
      error: errorResponseEditingShippingPriceList,
    },
  ] = useEditShippingPriceListMutation();

  const [
    deleteShippingPriceList,
    {
      isLoading: deletingShippingPriceList,
      isSuccess: deletedShippingPriceList,
      isError: errorDeletingShippingPriceList,
      error: errorResponseDeletingShippingPriceList,
    },
  ] = useDeleteShippingPriceListMutation();

  const handleAddShippingPriceList = async (
    shippingPriceListNew,
    shippingPriceListWeightRangeList
  ) => {
    shippingPriceListNew = {
      ...shippingPriceListNew,
      weightRanges: shippingPriceListWeightRangeList,
    };
    await addShippingPriceList(shippingPriceListNew).unwrap();
    setOpenAddShippingPriceListPopup(false);
    setShippingPriceListToAdd({
      id: 0,
      code: "",
      description: "",
      shippingCost: "",
    });
  };

  const handleEditShippingPriceList = async (
    shippingPriceListEdited,
    shippingPriceListWeightRangeList
  ) => {
    shippingPriceListEdited = {
      ...shippingPriceListEdited,
      weightRanges: shippingPriceListWeightRangeList,
    };
    await editShippingPriceList(shippingPriceListEdited).unwrap();
    setOpenEditShippingPriceListPopup(false);
    setShippingPriceListToEdit({
      id: 0,
      code: "",
      description: "",
      shippingCost: "",
    });
  };

  const handleDeleteConfirmation = async () => {
    await deleteShippingPriceList(shippingPriceListToDelete.id);
    setShowConfirmationMessage(false);
  };
  useEffect(() => {
    if (addedShippingPriceList) {
      setShow(true);
    } else if (errorAddingShippingPriceList) {
    }
  }, [addingShippingPriceList]);

  return (
    <main>
      <ModalConfirmationRequest
        handleConfirm={handleDeleteConfirmation}
        showModal={showConfirmationMessage}
        setShowModal={setShowConfirmationMessage}
        message={confirmationMessage}
      />
      <SimpleNotification show={show} setShow={setShow} />
      <AddShippingPriceListPopup
        shippingPriceListToAdd={shippingPriceListToAdd}
        setShippingPriceListToAdd={setShippingPriceListToAdd}
        handleSubmit={handleAddShippingPriceList}
        open={openAddShippingPriceListPopup}
        setOpen={setOpenAddShippingPriceListPopup}
      />
      <EditShippingPriceListPopup
        shippingPriceListEdited={shippingPriceListToEdit}
        setShippingPriceListEdited={setShippingPriceListToEdit}
        handleSubmit={handleEditShippingPriceList}
        open={openEditShippingPriceListPopup}
        setOpen={setOpenEditShippingPriceListPopup}
      />

      {loadingShippingPriceList ? (
        <TableLoading />
      ) : loadedShippingPriceList ? (
        <ListTable>
          <HeaderTable>
            <RowTable>
              <HeaderColTable>Codice</HeaderColTable>
              <HeaderColTable className={"text-center"}>Predefinito</HeaderColTable>
              <HeaderColTable className={"text-center"}>Premium</HeaderColTable>
              <HiddenHeaderColTable />
            </RowTable>
          </HeaderTable>
          <BodyTable>
            {shippingPriceLists.map((shippingPriceList) => (
              <RowShippingPriceList
                key={shippingPriceList.id}
                shippingPriceList={shippingPriceList}
                setShippingPriceListToEdit={setShippingPriceListToEdit}
                setShippingPriceListToDelete={setShippingPriceListToDelete}
                setConfirmationMessage={setConfirmationMessage}
                setShowConfirmationMessage={setShowConfirmationMessage}
                setOpenEditShippingPriceListPopup={
                  setOpenEditShippingPriceListPopup
                }
              />
            ))}
          </BodyTable>
        </ListTable>
      ) : errorLoadingShippingPriceList ? (
        <p>{errorResponseLoadingShippingPriceList}</p>
      ) : (
        <></>
      )}
      <div className="flex border-t border-gray-100 pt-6">
        <button
          type="button"
          onClick={() => setOpenAddShippingPriceListPopup(true)}
          className="text-sm font-semibold leading-6 text-green-facile hover:text-green-facile"
        >
          <span aria-hidden="true">+</span> Aggiungi Listino Spedizione
        </button>
      </div>
    </main>
  );
};
