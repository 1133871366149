import React, { useState } from "react";
import { Card, CardContent, debounce } from "@mui/material";
import PopupDialog from "../../../components/custom/PopupDialog";
import { useLazyGetProductsCatalogQuery } from "../../../services/catalogApi";
import { LoadingIcon } from "../../../components/custom/LoadingIcon";
import BadgeComponent from "../../../components/custom/BadgeComponent";
import { imagesUrl } from "../../../config/baseUrl";
import { ButtonGreen } from "../../../components/ButtonGreen";
import { PlusIcon } from "@heroicons/react/solid";
import { useAddCustomerProductInventoryMutation } from "../../../services/customerProductInventoryApi";
import { DropDownCustomer } from "../../../components/customer/DropDownCustomer";

function AddCustomerProductInventoryPopup({ open, setOpen }) {
    const [customerProductInventory, setCustomerProductInventory] = useState({
        customer: null,
        product: null,
        qty: 0
    })
    const [searchText, setSearchText] = useState("");
    const [errorFetch, setErrorFetch] = useState("");
    const [getProductsCatalog, { data: catalog, isLoading, isSuccess, isError, error }] = useLazyGetProductsCatalogQuery();
    const [addCustomerProductInventory, { isLoading: isAdding }] = useAddCustomerProductInventoryMutation();

    const handleAddInventory = async (e) => {
        e.preventDefault();
        if (customerProductInventory.customer && customerProductInventory.product && customerProductInventory.qty > 0) {
            try {
                await addCustomerProductInventory({
                    registeredUserId: customerProductInventory.customer.id,
                    productCatalogId: customerProductInventory.product.productCatalogId,
                    qty: customerProductInventory.qty
                }).unwrap();
                setCustomerProductInventory({
                    customer: null,
                    product: null,
                    qty: 0
                })
                setOpen(false);
                setErrorFetch("");
            } catch (error) {
                setErrorFetch(`Inserimento Prodotto In Inventario Fallito: ${error}`)
            }
        }
    };

    const handleInputChange = debounce((event) => {
        const value = event.target.value;
        setSearchText(value);
        getProductsCatalog({ searchText: value, page: 1, rowsPage: 10 });
    }, 300);
    


    return (
        <PopupDialog open={open} setOpen={setOpen}>
            <DropDownCustomer isSearchable={true} selectedValue={{}} onSelectedValue={(customer) => setCustomerProductInventory({ ...customerProductInventory, customer: customer?.value})} />
            <div className="mt-2">
                <label>Cerca Prodotto Per Sku o Nome</label>
                <input
                    type="text"
                    placeholder="Cerca prodotti..."
                    onChange={handleInputChange}
                    className="p-2 border rounded w-full"
                />
            </div>
            <section id="products-list" className="mt-3">
                {isLoading ? <LoadingIcon size={"h-4 w-4"} /> : 
                catalog?.products?.filter((p) => customerProductInventory.product == null ? true : customerProductInventory.product.productCatalogId === p.productCatalogId ).map((p, index) => (
                    <div onClick={() => setCustomerProductInventory({ ...customerProductInventory, product: customerProductInventory.product?.productCatalogId !== p.productCatalogId ? p : null })} 
                        className={`${p.productCatalogId === customerProductInventory.product?.productCatalogId && "border border-black"} overflow-hidden rounded-lg bg-white shadow mb-2 cursor-pointer hover:bg-gray-50`} 
                        key={index}
                        >
                        <div className="px-4 py-5 sm:p-6 flex justify-between flex-wrap">
                            <div className="flex gap-2 items-center">
                                <div className="size-20 shrink-0 overflow-hidden rounded-lg bg-gray-200 sm:size-20">
                                    {p?.images?.length > 0 && <img src={`${imagesUrl}${p.images[0].src}`} className="size-full object-cover" />}
                                </div>
                                <div className="flex flex-col gap-1 max-w-72">
                                    <span>{p.name}</span>
                                    <BadgeComponent text={p.sku} background={"green"} /> 
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </section>
            <form className="flex gap-2 justify-end" onSubmit={handleAddInventory}>
                <input 
                    type="number"
                    placeholder="Inserisci la Q.tà..."
                    className="p-2 border rounded flex-1"
                    min={1}
                    aria-describedby="qty-error"
                    value={Number(customerProductInventory.qty)}
                    onChange={(e) => setCustomerProductInventory({ ...customerProductInventory, qty: Number(e.target.value) })}
                />

                <ButtonGreen CustomIcon={PlusIcon} text={"Inserisci Pezzi"} type={"submit"} />
            </form>
            <section className="max-w-md mt-2">
                {(customerProductInventory.customer !== null && customerProductInventory.product !== null && customerProductInventory.qty > 0) &&
                    <span>Stai inserendo <b>{customerProductInventory.qty}pz</b> di <b>{customerProductInventory.product.sku}</b><br/> nell'inventario del cliente <b>{customerProductInventory.customer?.name ?? ""} {customerProductInventory.customer?.surname ?? ""}</b></span>
                }
            </section>
            {errorFetch !== "" &&
                <section id="error-message" className="max-w-md mt-3">
                    <span className="inline-flex items-center rounded-md bg-red-100 px-2.5 py-1.5 text-xs font-medium text-red-700">{errorFetch}</span>
                </section>
            }
        </PopupDialog>
    );
}

export default AddCustomerProductInventoryPopup;