import React, { useEffect, useState } from 'react'
import { useGetLogisticsStockPriceListPricesByUserIdQuery } from '../../../services/logisticsStockPriceListApi';
import { useSelector } from 'react-redux';
import PopupDialog from '../../../components/custom/PopupDialog';
import { Radio, RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { NextActionEnum } from '../../../enums/NextActionEnum';
import { ButtonGreen } from '../../../components/ButtonGreen';
import { SaveIcon } from '@heroicons/react/solid';
import moment from 'moment';
import { HandlingStatusEnum, HandlingStatusRadioButtons } from '../../../enums/HandlingStatusEnum';
import { useGetHoldByShipmentIdQuery, useInsertShipmentOnHoldMutation } from '../../../services/shipmentOnHoldApi';
import useHandleMutationResponse from '../../../hooks/useHandleMutationResponse';
import { DatePickerBasic } from '../../../components_used/DatePickerBasic';
import TextCopyToClipboard from '../../../components/catalog/TextCopyToClipboard';



export function ManageShipmentOnHoldPopupUser({ trackingNumber, open, setOpen, shippingPriceCustomer, shipmentId, setMutationResponse }) {
  
  
  const { data: prices, isLoading: loadingPrices, isSuccess: loadedPrices } = useGetLogisticsStockPriceListPricesByUserIdQuery(undefined, {
    skip: !open
  });
  
  const { data, isLoading: loadingShipmentOnHold, isSuccess: loadedShipmentOnHold, fulfilledTimeStamp } = useGetHoldByShipmentIdQuery(shipmentId, {
    refetchOnMountOrArgChange: true,
    skip: !open,
  });
  
  const [dataError, setDataError] = useState(undefined)
  const [shipmentOnHold, setShipmentOnHold] = useState({
    nextAction: "",
    nextActionAddress: "",
    nextActionNote: "",
    handlingDate: moment().format("yyyy-MM-DD"),
    handlingCost: 0,
    handlingStatus: HandlingStatusEnum.IN_LAVORAZIONE.id,
    handlingStatusMotivation: "",
    shipmentId
  })
  
  const [
    insertShipmentHold,
    {
      isLoading: insertingShipmentHold,
      isSuccess: insertedShipmentHold,
      isError: errorInsertingEvent,
      error: errorInsertingText,
    },
  ] = useInsertShipmentOnHoldMutation();
  
  useHandleMutationResponse({
    successEvent: insertedShipmentHold,
    errorEvent: errorInsertingEvent,
    successMessage: "Giacenza Gestita Con Successo",
    errorMessage: errorInsertingText,
    setMutationResponse,
    dependencies: [insertingShipmentHold],
    onSuccess: () => setOpen(false)
  });
  
  const handlingConfirm = async () => {
    if(shipmentOnHold.nextAction === ""){
      setDataError("Selezionare un azione per lo svincolo.")
      return;
    }
    if(shipmentOnHold.nextAction === NextActionEnum['riconsegna al nuovo destinatario'] && shipmentOnHold.nextActionAddress === ""){
      setDataError("Inserire un indirizzo valido per la riconsegna al nuovo destinatario.")
      return;
    }
    await insertShipmentHold({  ...shipmentOnHold, nextAction: shipmentOnHold.nextAction })
  }
  
  useEffect(() => {
    if(loadedShipmentOnHold) setShipmentOnHold({ ...data, handlingDate: moment(data.handlingDate).format("yyyy-MM-DD") })
  }, [fulfilledTimeStamp])

  useEffect(() => {
    setShipmentOnHold({     
      nextAction: "",
      nextActionAddress: "",
      nextActionNote: "",
      handlingDate: moment().format("yyyy-MM-DD"),
      handlingCost: 0,
      handlingStatus: HandlingStatusEnum.IN_LAVORAZIONE.id,
      handlingStatusMotivation: "",
      shipmentId })
  }, [shipmentId])


  return <PopupDialog open={open} setOpen={setOpen} >
    <div className="flex justify-center">
      <TextCopyToClipboard text={trackingNumber} textHover={"COPIA"} />
    </div>
    <DatePickerBasic min={moment().format("yyyy-MM-DD")} className={"mb-3"} label={"Data Svincolo"} disabled={shipmentOnHold.id !== undefined} value={shipmentOnHold.handlingDate} onChange={(e) => setShipmentOnHold({ ...shipmentOnHold, handlingDate: e.target.value})} />
    <div className='mb-2'>
        <label>Nota Svincolo</label>
        <input
          disabled={shipmentOnHold.id !== undefined}
          className="block w-full rounded-xl border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
          type="textarea"
          value={shipmentOnHold.nextActionNote}
          onChange={(e) => setShipmentOnHold({ ...shipmentOnHold, nextActionNote: e.target.value})}
        />
      </div>
    <RadioGroup
        disabled={shipmentOnHold.id !== undefined}
        value={prices?.find((p) => p.actionType === shipmentOnHold.nextAction) ?? null}
        onChange={(value) =>  setShipmentOnHold({ ...shipmentOnHold, nextAction: value.actionType, handlingCost: value.price == 0 && value.shipmentValuePercentage == 0 ? 0 : value.price + (value.shipmentValuePercentage > 0 ? (shippingPriceCustomer * (value.shipmentValuePercentage / 100)) : 0)})}
        className="grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4"
      >
        {prices?.map((p) => (
          <Radio
            key={p.id}
            value={p}
            className="group relative flex cursor-pointer rounded-lg border border-gray-300 bg-white p-4 shadow-sm focus:outline-none data-[focus]:border-green-600 data-[focus]:ring-2 data-[focus]:ring-green-600"
          >
            <span className="flex flex-1">
              <span className="flex flex-col">
                <span className="block text-sm font-medium text-gray-900">{p.actionType}</span>
                <span className="mt-1 flex items-center text-sm text-gray-500">{p.price == 0 && p.shipmentValuePercentage == 0  ? 'Nessun Costo' : 
                p.price == 0 && p.shipmentValuePercentage > 0 ?
                    `il ${p.shipmentValuePercentage}% della spedizione (${shippingPriceCustomer}€)` : p.price > 0 && p.shipmentValuePercentage == 0 ? `${p.price}€` :
                    `${p.price}€ + ${p.shipmentValuePercentage}% della spedizione (${shippingPriceCustomer}€)`}</span> 
                <span className="mt-6 text-sm font-medium text-gray-900">{p.price == 0 && p.shipmentValuePercentage == 0 ? 'Gratis' : `Costo: ${p.price + (p.shipmentValuePercentage > 0 ? (shippingPriceCustomer * (p.shipmentValuePercentage / 100)) : 0)}€`}</span>
              </span>
            </span>
            <CheckCircleIcon
              aria-hidden="true"
              className="size-5 text-green-600 group-[&:not([data-checked])]:invisible"
            />
            <span
              aria-hidden="true"
              className="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent group-data-[focus]:border group-data-[checked]:border-green-600"
            />
          </Radio>
        ))}
      </RadioGroup>
      {shipmentOnHold.nextAction === NextActionEnum['riconsegna al nuovo destinatario'] &&
        <section id='new-address' className='mt-3'>
          <label>Inserisci nuova destinazione</label>
          <input 
            disabled={shipmentOnHold.id !== undefined}
            value={shipmentOnHold.nextActionAddress}
            onChange={(e) => setShipmentOnHold({ ...shipmentOnHold, nextActionAddress: e.target.value })}
            className='mt-1 block w-full rounded-xl border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6' />
        </section>
      }
      {shipmentOnHold.handlingStatusMotivation  !== "" && 
        <div className='mt-2'>
          <label>Perchè lo svincolo non è possibile ?</label>
          <input
            disabled={shipmentOnHold.id !== undefined}
            className="block w-full rounded-xl border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            type="textarea"
            value={shipmentOnHold.handlingStatusMotivation}
          />
      </div>
      }
      {(shipmentOnHold.nextAction !== "" && shipmentOnHold.id === undefined || (shipmentOnHold.nextAction === NextActionEnum['riconsegna al nuovo destinatario'] && shipmentOnHold.nextActionAddress !== "" && shipmentOnHold.id === undefined)) &&
        <section id='new-address' className='mt-2 flex justify-end'>
          <ButtonGreen CustomIcon={SaveIcon} onClick={() => handlingConfirm()} text={"Conferma"} />
        </section>
      }
      {dataError &&
        <span className="inline-flex items-center justify-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700 w-full text-center mt-1">
          {dataError}
        </span>
      }
  </PopupDialog>
  
}

