import React, { useEffect, useState } from "react";
import ListTable from "../../custom/ListTable";
import HeaderTable from "../../custom/HeaderTable";
import RowTable from "../../custom/RowTable";
import HeaderColTable from "../../custom/HeaderColTable";
import HiddenHeaderColTable from "../../custom/HiddenHeaderColTable";
import BodyTable from "../../custom/BodyTable";
import CellRowTable from "../../custom/CellRowTable";
import ButtonTable from "../../custom/ButtonTable";
import { PlusIcon, TrashIcon } from "@heroicons/react/solid";
import { TableLoading } from "../LoadingTable";
import { InputField } from "../../../components_used/InputField";
import { useGetAllByShippingPriceListIdQuery } from "../../../services/shippingPriceListWeightRangeApi";

function ListShippingPriceListWeightRange({
  shippingPriceListId,
  shippingPriceListWeightRange,
  setShippingPriceListWeightRange,
}) {
  const {
    data: shippingPriceListWeightRangeDb,
    isLoading,
    isSuccess,
    isError,
    error,
    isUninitialized,
  } = useGetAllByShippingPriceListIdQuery(shippingPriceListId, {
    skip: shippingPriceListId == null,
  });

  useEffect(() => {
    if (isSuccess) {
      setShippingPriceListWeightRange(shippingPriceListWeightRangeDb);
    }
  }, [isLoading]);
  return (
    <main>
      {isLoading ? (
        <TableLoading />
      ) : isSuccess || isUninitialized ? (
        <ListTable>
          <HeaderTable>
            <RowTable>
              <HeaderColTable className={"text-center"} colSpan={2}>
                Peso
              </HeaderColTable>
              <HiddenHeaderColTable />
              <HeaderColTable>
                <ButtonTable
                  onClick={() =>
                    setShippingPriceListWeightRange([
                      ...shippingPriceListWeightRange,
                      {
                        shippingPriceListId: shippingPriceListId,
                        weightFrom: 0,
                        weightTo: 0,
                        freightCost: 0
                      },
                    ])
                  }
                  size={"h-3 w-3"}
                  Icon={PlusIcon}
                  color={
                    "from-green-400 via-green-500 to-green-600 focus:ring-green-300 dark:focus:ring-green-800"
                  }
                />
              </HeaderColTable>
            </RowTable>
            <RowTable>
              <HeaderColTable>Da</HeaderColTable>
              <HeaderColTable>A</HeaderColTable>
              <HeaderColTable>Prezzo EXPRESS</HeaderColTable>
              <HiddenHeaderColTable />
            </RowTable>
          </HeaderTable>
          <BodyTable>
            {shippingPriceListWeightRange?.map((priceList, index) => (
              <RowTable key={index}>
                <CellRowTable>
                  <InputField
                    value={priceList.weightFrom}
                    onChange={(e) => {
                      setShippingPriceListWeightRange(
                        shippingPriceListWeightRange.map((splwr) => {
                          if (splwr == priceList) {
                            return { ...splwr, weightFrom: e.target.value };
                          }
                          return splwr;
                        })
                      );
                    }}
                  />
                </CellRowTable>
                <CellRowTable>
                  {" "}
                  <InputField
                    value={priceList.weightTo}
                    onChange={(e) => {
                      setShippingPriceListWeightRange(
                        shippingPriceListWeightRange.map((splwr) => {
                          if (splwr == priceList) {
                            return { ...splwr, weightTo: e.target.value };
                          }
                          return splwr;
                        })
                      );
                    }}
                  />
                </CellRowTable>
                <CellRowTable>
                  {" "}
                  <InputField
                    value={priceList.freightCost}
                    onChange={(e) => {
                      setShippingPriceListWeightRange(
                        shippingPriceListWeightRange.map((splwr) => {
                          if (splwr == priceList) {
                            return {
                              ...splwr,
                              freightCost: e.target.value,
                            };
                          }
                          return splwr;
                        })
                      );
                    }}
                  />
                </CellRowTable>
                <CellRowTable>
                  <ButtonTable
                    Icon={TrashIcon}
                    color={
                      "from-red-400 via-red-500 to-red-600 focus:ring-red-300 dark:focus:ring-red-800"
                    }
                    onClick={() => {
                      setShippingPriceListWeightRange(
                        shippingPriceListWeightRange.filter(
                          (splwr) => splwr !== priceList
                        )
                      );
                    }}
                  />
                </CellRowTable>
              </RowTable>
            ))}
          </BodyTable>
        </ListTable>
      ) : isError ? (
        <p>{error}</p>
      ) : (
        <></>
      )}
    </main>
  );
}

export default ListShippingPriceListWeightRange;
