import { userApi } from "./userApi";

const codShippingCostApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllCodShippingCost: builder.query({
      query: () => `cod-shipping-costs/get-all`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "CodShippingCost", id })),
              "CodShippingCosts", // Questo mantiene comunque il tag per l'intera lista
            ]
          : ["CodShippingCosts"],
    }),
    addCodShippingCost: builder.mutation({
      query: (codShippingCost) => ({
        url: `cod-shipping-costs/add`,
        method: "POST",
        body: { ...codShippingCost },
      }),
      invalidatesTags: ["CodShippingCosts"],
    }),
    updateCodShippingCost: builder.mutation({
      query: ({ codShippingCost }) => ({
        url: `cod-shipping-costs/update`,
        method: "PUT",
        body: { codShippingCost },
      }),
      invalidatesTags: (result, error, { codShippingCost }) => [
        { type: "CodShippingCost", id: codShippingCost.id },
      ],
    }),
    deleteCodShippingCost: builder.mutation({
      query: (id) => ({
        url: `cod-shipping-costs/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["CodShippingCosts"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllCodShippingCostQuery,
  useAddCodShippingCostMutation,
  useDeleteCodShippingCostMutation,
  useUpdateCodShippingCostMutation
} = codShippingCostApi;
