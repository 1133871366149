import { userApi } from "./userApi";

const customerProductInventoryApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllCustomerProductInventory: builder.query({
      query: () => ({
        url: `customer-product-inventory/get-all`,
        method: "POST",
        //body: { filter, searchText },
      }),
      providesTags: ["CustomerProductInventory"],
    }),
    updateCustomerProductInventory: builder.mutation({
      query: ({ id, qty }) => ({
        url: `customer-product-inventory/update/${id}`,
        method: "PUT",
        body: { qty },
      }),
      invalidatesTags: ["CustomerProductInventory"],
    }),
    addCustomerProductInventory: builder.mutation({
      query: ({ registeredUserId, productCatalogId, qty }) => ({
        url: `customer-product-inventory/add`,
        method: "POST",
        body: { registeredUserId, productCatalogId, qty },
      }),
      invalidatesTags: ["CustomerProductInventory"],
    }),
    deleteCustomerProductInventory: builder.mutation({
      query: (id) => ({
        url: `customer-product-inventory/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["CustomerProductInventory"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllCustomerProductInventoryQuery,
  useUpdateCustomerProductInventoryMutation,
  useDeleteCustomerProductInventoryMutation,
  useAddCustomerProductInventoryMutation
} = customerProductInventoryApi;
