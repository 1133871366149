import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { useGetLogisticsStockPriceListQuery } from "../../services/logisticsStockPriceListApi";
import Select from "react-select";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const DropDownLogisticsStockPriceList = ({ onSelectedValue, selectedValue, isSearchable = false }) => {
  const { data: logisticsStockPriceLists, isLoading, isSuccess, isError, error } = useGetLogisticsStockPriceListQuery();

  const onChange = (value) => {
    onSelectedValue(value);
  };

  return (
    isSuccess && (
      <div className="text-left">
        <span className="block text-sm font-medium leading-6 text-gray-900">
          Listino Giacenze
        </span>
          <Select
            menuShouldScrollIntoView={true}
            defaultValue={selectedValue ? { id: selectedValue.id, value: selectedValue, label: selectedValue.name } : null}
            isSearchable={isSearchable}
            options={[{ id: null, value: null, label: "Nessuno" }, ...logisticsStockPriceLists.map((item) => ({id: item.id, value:item, label: item.name}))]}
            onChange={onChange}
          />
        
      </div>
    )
  );
};
