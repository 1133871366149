import { useEffect } from "react";

const useHandleMutationResponse = ({
  successEvent,
  errorEvent,
  successMessage,
  errorMessage,
  setMutationResponse,
  onSuccess = () => {},
  onError = () => {},
  dependencies = [],
}) => {
  useEffect(() => {
    if (errorEvent) {
      onError();
      setMutationResponse({
        message: errorMessage?.data?.message ?? "Errore generico",
        show: true,
        success: false,
      });
    }
    if (successEvent) {
      onSuccess();
      setMutationResponse({
        message: successMessage,
        show: true,
        success: true,
      });
    }
  }, [errorEvent, successEvent, ...dependencies]); // Dipendenze dinamiche
};

export default useHandleMutationResponse;