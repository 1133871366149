import { userApi } from "./userApi";

const excelApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    generateCashFlowExcel: builder.mutation({
      query: ({ filter }) => ({
        url: `excel/cashflow`,
        method: "POST",
        body: { filter },
  
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGenerateCashFlowExcelMutation,
} = excelApi;
