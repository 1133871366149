import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoadingIcon } from "../../custom/LoadingIcon";
import { usePayProductsMutation } from "../../../services/stripeApi";
import RowShopifyOrderToFulfill from "./RowShopifyOrderToFulfill";
import PopupDialog from "../../custom/PopupDialog";
import {
  FulfillmentStatus,
  FulfillmentStatusList,
} from "../../../enums/FulfillmentStatus";
import { Radio, RadioGroup } from "@headlessui/react";
import {
  useGetAllFulfillmentStatusQuery,
  useUpdateFulfillmentRequestMutation,
} from "../../../services/orderApi";
import SimpleNotification from "../../SimpleNotification";
import RowShopifyOrderToConfirm from "./RowShopifyOrderToConfirm";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ListShopifyOrderToConfirm({
  getAllOrders,
  showOrderProducts,
  orders,
  selectedOrder,
  setSelectedOrder,
  printingMode,
  handleAddItem,
  handleItemToPrint,
  selectedOrderToPrint,
  isLoading,
  isSuccess,
  isError,
  error,
}) {
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const [selectedOrderRow, setSelectedOrderRow] = useState(orders[0] ?? []);
  const [payProducts] = usePayProductsMutation();
  const [showFulfillmentStatus, setShowFulfillmentStatus] = useState(false);
  const [notificationSuccess, setNotificationSuccess] = useState(true);

  const {
    data: fulfillmentStatusList,
    isLoading: loadingFulfillmentStatus,
    isSuccess: successFulfillmentStatus,
  } = useGetAllFulfillmentStatusQuery(undefined, {
    skip: !showFulfillmentStatus,
  });

  const [
    updateFulfillment,
    {
      data: dataUpdateFulfillment,
      isLoading: loadingUpdateFulfillment,
      isSuccess: successUpdateFulfillment,
      isError: errorUpdateFulfillmentEvent,
      error: errorUpdateFulfillmentText,
    },
  ] = useUpdateFulfillmentRequestMutation();

  const onClickPay = async (order) => {
    if ((order?.amount_paid ?? 0) !== 0) {
      setNotificationSuccess(true);
      setNotificationMessage("Ordine Già Pagato");
      setShowNotification(true);
      return;
    }
    const res = await payProducts({
      storeName: order.store_name,
      orderNumber: order.order_number,
    }).then((response) => {
      if (response.error) {
        setNotificationSuccess(false);
        setNotificationMessage(response.error.data.message);
        setShowNotification(true);
        return;
      }
      window.location.href = res.data;
    });
  };

  const onEditFulfillmentRequest = async (order) => {
    setSelectedOrderRow(order);
    setShowFulfillmentStatus(true);
  };

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <SimpleNotification
        message={notificationMessage}
        show={showNotification}
        setShow={setShowNotification}
        success={notificationSuccess}
      />

      <PopupDialog
        open={showFulfillmentStatus}
        setOpen={setShowFulfillmentStatus}
      >
        <RadioGroup
          value={{
            id: selectedOrderRow.fulfillment_status_id,
            name: selectedOrderRow.fulfillment_status_name,
          }}
          onChange={(e) => {
          
            updateFulfillment({
              orders: [selectedOrderRow],
              toFulfill: true,
              fulfillmentStatusId: e.id,
            }).finally(() => {
              setSelectedOrderRow({
                ...selectedOrderRow,
                fulfillment_status_id: e.id,
                fulfillment_status_name: e.name,
              });
              setShowFulfillmentStatus(false);
              setNotificationMessage("Stato Evasione Aggiornato Correttamente");
              setShowNotification(true);
              setNotificationSuccess(true);
              getAllOrders();
            });
          }}
          className="-space-y-px rounded-md bg-white"
        >
          {fulfillmentStatusList?.map((fulfillmentStatus, index) => (
            <Radio
              key={index}
              value={fulfillmentStatus}
              aria-label={fulfillmentStatus}
              // aria-description={setting.description}
              className={({ checked }) =>
                classNames(
                  checked
                    ? "z-10 border-indigo-200 bg-indigo-50"
                    : "border-gray-200",
                  "rounded-bl-md rounded-br-md relative flex cursor-pointer border p-4 focus:outline-none"
                )
              }
            >
              {({ focus, checked }) => (
                <>
                  <span
                    className={classNames(
                      selectedOrderRow.fulfillment_status_id ==
                        fulfillmentStatus.id
                        ? "border-transparent bg-indigo-600"
                        : "border-gray-300 bg-white",
                      focus ? "ring-2 ring-indigo-600 ring-offset-2" : "",
                      "mt-0.5 flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full border"
                    )}
                    aria-hidden="true"
                  >
                    <span className="h-1.5 w-1.5 rounded-full bg-white" />
                  </span>
                  <span className="ml-3 flex flex-col">
                    <span
                      className={classNames(
                        checked ? "text-indigo-900" : "text-gray-900",
                        "block text-sm font-medium"
                      )}
                    >
                      {fulfillmentStatus?.name ?? ""}
                    </span>
                  </span>
                </>
              )}
            </Radio>
          ))}
        </RadioGroup>
      </PopupDialog>
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
          <tr>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
              <span className="sr-only">Edit</span>
            </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
              <input
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-green-facile focus:ring-green-facile"
                onChange={(e) =>
                  e.target.checked
                    ? setSelectedOrder(
                        orders.filter(
                          (ord) =>
                            ord.tracking_number == null &&
                            (ord?.fullfilment_status ?? "") != "Completato"
                        )
                      )
                    : setSelectedOrder([])
                }
              />
            </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
              <span className="sr-only">Edit</span>
            </th>

            <th
              scope="col"
              className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900 sm:pl-3"
            >
              Data
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900 sm:pl-3"
            >
              Ordine
            </th>
            {/* <th
              scope="col"
              className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900"
            >
              Stato di Evasione
            </th> */}
            <th
              scope="col"
              className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900"
            >
              Cliente
            </th>
            {auth.roles.find((role) => role == 1) ? (
              <th
                scope="col"
                className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900"
              >
                Cliente CODFacile
              </th>
            ) : (
              <></>
            )}
            {/* <th
              scope="col"
              className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900"
            >
              Stato Spedizione
            </th> */}
            <th
              scope="col"
              className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900"
            >
              COD
            </th>
            {/* <th
              scope="col"
              className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900"
            >
              Costo Prodotti
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900"
            >
              Costo Spedizione
            </th> */}
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3"></th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3"></th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <LoadingIcon className="h-4 w-4" />
          ) : isSuccess ? (
            orders.map((order) => (
              <RowShopifyOrderToConfirm
                onEditFulfillmentRequest={onEditFulfillmentRequest}
                showOrderProducts={showOrderProducts}
                auth={auth}
                navigate={navigate}
                onClickPay={onClickPay}
                key={order.id}
                order={order}
                selectedOrder={selectedOrder}
                printingMode={printingMode}
                handleAddItem={handleAddItem}
                handleItemToPrint={handleItemToPrint}
                selectedOrderToPrint={selectedOrderToPrint}
              />
            ))
          ) : isError ? (
            <span>{error.data}</span>
          ) : (
            <></>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default ListShopifyOrderToConfirm;
