import React from "react";
import { useGetAllCustomerQuery } from "../../services/customerApi";
import Select from "react-select";

export const DropDownCustomer = ({ onSelectedValue, selectedValue, isSearchable = false, openOnClick = false }) => {
  const { data: customers, isLoading, isSuccess, isError, error } = useGetAllCustomerQuery("%20");

  const onChange = (value) => {
    onSelectedValue(value);
  };
  return (
    isSuccess && (
      <div className="text-left">
        <span className="block text-sm font-medium leading-6 text-gray-900">
          Cliente
        </span>
        <Select
          isClearable={true}
          placeholder="Seleziona un cliente"
          openMenuOnClick={openOnClick}
          menuShouldScrollIntoView={true}
          value={Object.keys(selectedValue ?? {}).length > 0 ? { id: selectedValue.id, value: selectedValue, label: `${selectedValue?.name ?? ""} ${selectedValue?.surname ?? ""}` } : null}
          defaultValue={Object.keys(selectedValue ?? {}).length > 0 ? { id: selectedValue.id, value: selectedValue, label: `${selectedValue?.name ?? ""} ${selectedValue?.surname ?? ""}` } : { id: null, value: null, label: "Nessuno" }}
          isSearchable={isSearchable}
          options={[{ id: null, value: null, label: "Nessuno" }, ...customers.map((cus) => ({ id: cus.id, value: cus, label: `${cus?.name ?? ""} ${cus?.surname ?? ""}` }))]}
          onChange={onChange}
        />
      </div>
    )
  );
};