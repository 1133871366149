import { useEffect, useState } from "react";
import InputWithIcon from "./InputWithIcon";
import { SimpleButton } from "./SimpleButton";
import {
  useSaveShopifyKeyMutation,
  useUpdateShopifyKeyMutation,
} from "../services/userApi";
import SimpleNotification from "../components/SimpleNotification";
import ShopifyKeyList from "./ShopifyKeyList";
import SecondaryButton from "./SecondaryButton";
import { ErrorBanner } from "./custom/ErrorBanner";
import { KeyIcon, ShoppingCartIcon } from "@heroicons/react/outline";

const ShopifyKeyForm = () => {
  const [saveShopifyKey, { isLoading, isSuccess, isError, error }] =
    useSaveShopifyKeyMutation();

  const [
    updateShopifyKey,
    {
      isLoading: updateLoading,
      isSuccess: updateSuccesss,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateShopifyKeyMutation();

  const [accessToken, setAccessToken] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [secretApiKey, setSecretApiKey] = useState("");
  const [storeName, setStoreName] = useState("");
  const [aliasStore, setAliasStore] = useState("");
  const [show, setShow] = useState(false);
  const [idAppKeys, setIdAppKeys] = useState(0);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (isSuccess) {
      setShow(true);
    } else if (isError) {
      setErrorMessage(error.data.message);
      setShowErrorMessage(true);
    }
  }, [isLoading]);

  useEffect(() => {
    if (updateSuccesss) {
      setShow(true);
    } else if (isUpdateError) {
    }
  }, [updateShopifyKey]);

  const onSave = async (e) => {
    e.preventDefault(e);
    if (storeName == "") {
      setErrorMessage("Inserisci il nome dello store");
      setShowErrorMessage(true);
      return;
    }
    if (!storeName.includes(".myshopify.com")) {
      setErrorMessage(
        "Inserire il dominio .myshopify.com come 'Nome del negozio'"
      );
      setShowErrorMessage(true);
      return;
    }
    try {
      await saveShopifyKey({
        accessToken,
        apiKey,
        secretApiKey,
        storeName,
        aliasStore,
      }).unwrap();

      setAccessToken("");
      setApiKey("");
      setSecretApiKey("");
      setStoreName("");
      setAliasStore("");
    } catch (error) {
      console.error("rejected", error);
    }
  };

  const onUpdate = async (e) => {
    e.preventDefault(e);
    if (storeName == "") {
      setErrorMessage("Inserisci il nome dello store");
      setShowErrorMessage(true);
      return;
    }
    if (!storeName.includes(".myshopify.com")) {
      setErrorMessage(
        "Inserire il dominio .myshopify.com come 'Nome del negozio'"
      );
      setShowErrorMessage(true);
      return;
    }
    try {
      await updateShopifyKey({
        accessToken,
        apiKey,
        secretApiKey,
        storeName,
        aliasStore,
        shopifyStoreId: idAppKeys,
      }).unwrap();

      setAccessToken("");
      setApiKey("");
      setSecretApiKey("");
      setStoreName("");
      setAliasStore("");
      setIdAppKeys(0);
    } catch (error) {
      console.error("rejected", error);
    }
  };

  const handleDoubleClickRow = (
    accessToken,
    apiKey,
    secretApiKey,
    storeName,
    aliasStore,
    idAppKeys
  ) => {
    setIdAppKeys(idAppKeys);
    setAccessToken(accessToken);
    setApiKey(apiKey);
    setSecretApiKey(secretApiKey);
    setStoreName(storeName);
    setAliasStore(aliasStore);
  };
  const handleCancelEditing = () => {
    setIdAppKeys(0);
    setAccessToken("");
    setApiKey("");
    setSecretApiKey("");
    setStoreName("");
    setAliasStore("");
  };

  return (
    <>
      <InputWithIcon
        Icon={KeyIcon}
        label={"Token di accesso"}
        value={accessToken}
        onChangeInput={(e) => setAccessToken(e)}
        //placeholder="Token di accesso"
      />
      <InputWithIcon
        Icon={ShoppingCartIcon}
        label={"Dominio Shopify"}
        value={storeName}
        onChangeInput={(e) => setStoreName(e)}
        placeholder="es. miostore.myshopify.com"
      />
      <InputWithIcon
        Icon={ShoppingCartIcon}
        label={"Alias del negozio"}
        value={aliasStore}
        onChangeInput={(e) => setAliasStore(e)}
        placeholder="Nome identificativo in piattaforma"
      />
      <InputWithIcon
        Icon={KeyIcon}
        label={"Chiave Api Segreta"}
        value={secretApiKey}
        onChangeInput={(e) => setSecretApiKey(e)}
        placeholder="Inserisci la chiave api segreta"
      />
      <div className="flex flex-wrap justify-start gap-4">
        <SimpleButton
          isLoading={updateLoading}
          textButton={idAppKeys !== 0 ? "Modifica" : "Salva"}
          onClick={(e) => (idAppKeys == 0 ? onSave(e) : onUpdate(e))}
        />
        {idAppKeys !== 0 ? (
          <SecondaryButton
            onClick={() => handleCancelEditing()}
            text={"Annulla"}
            color={"text-white"}
          />
        ) : (
          <></>
        )}
      </div>
      <SimpleNotification show={show} setShow={setShow} />
      <ShopifyKeyList handleDoubleClickRow={handleDoubleClickRow} />

      <ErrorBanner
        message={errorMessage}
        show={showErrorMessage}
        setShow={setShowErrorMessage}
      />
    </>
  );
};

export default ShopifyKeyForm;
