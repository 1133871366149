import { useGetMaintenanceModeQuery } from "../services/maintenanceApi";


const useMaintenanceMode = () => {
    const { data, error, isLoading } = useGetMaintenanceModeQuery();
    if (isLoading) {
        return false; // or you can handle loading state differently
    }
    
    if (error) {
        console.error('Error fetching maintenance mode:', error);
        return false;
    }
    
    const localStorageMaintenanceKey = localStorage.getItem('maintenanceKey');

    if (localStorageMaintenanceKey && localStorageMaintenanceKey === data?.key) {
        return false;
    }

    return data?.maintenance || false;
};

export default useMaintenanceMode;