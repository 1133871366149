import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, PencilIcon, TrashIcon } from "@heroicons/react/outline";
import { useEditLogisticsStockPriceMutation, useDeleteLogisticsStockPriceMutation, useGetLogisticsStockPriceListPricesByIdQuery } from "../../services/logisticsStockPriceListApi";
import ButtonTable from "../custom/ButtonTable";
import ListTable from "../custom/ListTable";
import HeaderTable from "../custom/HeaderTable";
import RowTable from "../custom/RowTable";
import HeaderColTable from "../custom/HeaderColTable";
import BodyTable from "../custom/BodyTable";
import CellRowTable from "../custom/CellRowTable";
import SimpleNotification from "../SimpleNotification";
import { LoadingIcon } from "../custom/LoadingIcon";

export default function EditLogisticsStockPricePopup({ logisticsStockPriceList, setOpen }) {
  const [editLogisticsStockPrice] = useEditLogisticsStockPriceMutation();
  const { data: prices, isLoading, isError } = useGetLogisticsStockPriceListPricesByIdQuery(logisticsStockPriceList.id, {
    refetchOnMountOrArgChange: true,
  });
  const [isEditingPrice, setIsEditingPrice] = useState({});
  const [localPrices, setLocalPrices] = useState([]);
  const [notification, setNotification] = useState({
    message: "",
    show: false,
    success: false
  });

  useEffect(() => {
    if (prices) {
      setLocalPrices(prices);
    }
  }, [prices]);

  const handleEditPrice = async (price) => {
    try {
      if(isEditingPrice.id !== price.id) {
        setIsEditingPrice(price);
        return;
      }
      await editLogisticsStockPrice({ ...price }).unwrap();
      setNotification({
        message: "Prezzo Listino Giacenza Modificato Correttamente",
        show: true,
        success: true
      });
      setIsEditingPrice({});
    } catch (error) {
      setNotification({
        message: "Modifica Prezzo Listino Giacenza Fallita",
        show: true,
        success: false
      });
    }
  };

return (
    <Transition.Root show={true} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <Dialog.Panel className="relative transform w-full overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                            <div>
                                <div className="mt-3 text-center sm:mt-5">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-base font-semibold leading-6 text-gray-900"
                                    >
                                        Modifica Prezzi Listino '{logisticsStockPriceList.name}'
                                    </Dialog.Title>
                                    
                                    <div className="mt-2">
                                        {isLoading ? (
                                            <LoadingIcon size={"h-5 w-5"} />
                                        ) : isError ? (
                                            <p>Error loading prices</p>
                                        ) : (
                                            <ListTable>
                                                <HeaderTable>
                                                    <RowTable>
                                                        <HeaderColTable>Azione</HeaderColTable>
                                                        <HeaderColTable className={"max-w-20"}>Prezzo</HeaderColTable>
                                                        <HeaderColTable  className={"max-w-30"}>+ % del valore della spedizione</HeaderColTable>
                                                        <HeaderColTable />
                                                    </RowTable>
                                                </HeaderTable>
                                                <BodyTable>
                                                    {localPrices.map((price) => (
                                                        <RowTable key={price.id}>
                                                            <CellRowTable>{price.actionType}</CellRowTable>
                                                            <CellRowTable className={"max-w-20"}>
                                                                <input
                                                                    disabled={isEditingPrice.id !== price.id}
                                                                    type="number"
                                                                    value={price.price}
                                                                    onChange={(e) => setLocalPrices(localPrices.map(p => p.id === price.id ? { ...p, price: e.target.value } : p))}
                                                                    className="border-solid border border-gray-200 rounded-md pl-1 w-full"
                                                                />
                                                            </CellRowTable>
                                                            <CellRowTable  className={"max-w-30"}>
                                                                <input
                                                                    disabled={isEditingPrice.id !== price.id}
                                                                    type="number"
                                                                    value={price.shipmentValuePercentage == 0 ? "" : price.shipmentValuePercentage}
                                                                    onChange={(e) => {
                                                                        let value = parseInt(e.target.value);
                                                                        if(isNaN(value)) {
                                                                            return;
                                                                        }
                                                                        setLocalPrices(localPrices.map(p => p.id === price.id ? { ...p, shipmentValuePercentage: value } : p))
                                                                    }}
                                                                    className="border-solid border border-gray-200 rounded-md pl-1 w-full"
                                                                />
                                                            </CellRowTable>
                                                            <CellRowTable>
                                                                <div className="flex gap-2">
                                                                    <ButtonTable
                                                                        Icon={isEditingPrice.id == price.id ? CheckIcon : PencilIcon}
                                                                        color={"from-green-400 via-green-500 to-green-600 focus:ring-green-300 dark:focus:ring-green-800"}
                                                                        onClick={() => handleEditPrice(price)}
                                                                    />
                                                                </div>
                                                            </CellRowTable>
                                                        </RowTable>
                                                    ))}
                                                </BodyTable>
                                            </ListTable>
                                        )}
                                    </div>
                                    <p className="text-left mt-2">Per il valore in percentuale sulla spedizione non è possibile inserire un valore decimale.</p>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
        <SimpleNotification
            message={notification.message}
            show={notification.show}
            setShow={() => setNotification({ ...notification, show: false })}
            success={notification.success}
        />
    </Transition.Root>
);
}