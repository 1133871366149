import { userApi } from "./userApi";

const shipmentApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllShipments: builder.mutation({
      query: ({ page, rowsPage, filter }) => ({
        url: `shipment/get-all/${page}/${rowsPage}`,
        method: "POST",
        body: { filter },
      }),
    }),
    getShipmentPages: builder.mutation({
      query: ({ filter }) => ({
        url: `shipment/get-shipment-pages`,
        method: "POST",
        body: { filter },
      }),
    }),
    getShipmentCostProducts: builder.mutation({
      query: (products) => ({
        url: `/shipment/get-shipment-cost-products`,
        method: "POST",
        body: { products },
      }),
    }),
    getAllProductsShipment: builder.query({
      query: ({ startDate, endDate, customer }) =>
        `shipment/get-all-products/${startDate}/${endDate}${customer ? `?customer=${customer ?? ""}` : ""}`,
      providesTags: ["Shipments"],
    }),
    getAllSpedisciOnlineStatus: builder.query({
      query: () => `shipment/spedisci-online-status`,
    }),
    getShipmentNumber: builder.query({
      query: ({ startDate, endDate }) =>
        `shipment/count/${startDate}/${endDate}`,
      providesTags: ["Shipments"],
    }),
    getCosts: builder.query({
      query: ({ startDate, endDate }) =>
        `shipment/cost/${startDate}/${endDate}`,
      providesTags: ["Shipments"],
    }),
    getEarnings: builder.query({
      query: ({ startDate, endDate }) =>
        `shipment/earning/${startDate}/${endDate}`,
      providesTags: ["Shipments"],
    }),
    getNett: builder.query({
      query: ({ startDate, endDate }) =>
        `shipment/nett/${startDate}/${endDate}`,
      providesTags: ["Shipments"],
    }),
    getLDVShipment: builder.query({
      query: ({ orderId }) => ({
        url: `shipment/order/${orderId}/get-ldv-pdf`,
        responseType: "arraybuffer",
      }),

      providesTags: ["Shipments"],
    }),
    // getLDVShipment: builder.query({
    //   query: ({ orderId }) => `shipment/sorder/${orderId}/get-ldv-pdf`,
    //   providesTags: ["Shipments"],
    // }),
    getTrackingDetails: builder.query({
      query: ({ trackingNumber }) =>
        `shipment/tracking-details/${trackingNumber}`,
      providesTags: ["Shipments"],
    }),
    printWaybillShipments: builder.mutation({
      query: ({ trackingNumbers }) => ({
        url: `/shipment/pdf-to-print`,
        method: "POST",
        body: trackingNumbers,
      }),
    }),
    // printWaybillShipments: builder.mutation({
    //   query: ({ trackingNumbers }) => ({
    //     url: `/shipment/pdf-to-print`,
    //     method: "POST",
    //     body: trackingNumbers,
    //   }),
    // }),
    updateShippingCost: builder.mutation({
      query: ({ trackingNumber, shippingCost }) => ({
        url: `/shipment/update-shipping-cost/${trackingNumber}`,
        method: "PUT",
        body: { shippingCost: shippingCost },
        responseHandler: "text",
      }),
      invalidatesTags: ["CashFlow"],
    }),
    updateShipmentOrderByCsv: builder.mutation({
      query: ({ storeName, csvBase64 }) => ({
        url: `/shipment/update-shipment-by-csv`,
        method: "PUT",
        body: { storeName, csvBase64 },
      }),
    }),
    deleteOne: builder.mutation({
      query: ({ shipment }) => ({
        url: `/shipment/delete-one`,
        method: "DELETE",
        body: { shipment },
      }),
      invalidatesTags: ["Shipments"],
    }),
  }),
  overrideExisting: false,
});

export const {
  //useGetAllShipmentsQuery,
  useGetAllShipmentsMutation,
  useGetAllProductsShipmentQuery,
  useGetShipmentNumberQuery,
  useGetLDVShipmentQuery,
  useLazyGetLDVShipmentQuery,
  useGetTrackingDetailsQuery,
  useLazyGetTrackingDetailsQuery,
  useGetCostsQuery,
  useGetEarningsQuery,
  useGetNettQuery,
  usePrintWaybillShipmentsMutation,
  useUpdateShippingCostMutation,
  useUpdateShipmentOrderByCsvMutation,
  //useGetShipmentPagesQuery,
  useGetShipmentPagesMutation,
  useDeleteOneMutation,
  useGetAllSpedisciOnlineStatusQuery,
  useGetShipmentCostProductsMutation,
} = shipmentApi;
