import React from "react";
import { useGetAllCustomerQuery } from "../services/customerApi";
import { LoadingIcon } from "./custom/LoadingIcon";
import Select from "react-select";
import { useGetAllSpedisciOnlineStatusQuery } from "../services/shipmentApi";

function DropDownShipmentStatus({ defaultValue, onChange, isSearchable = false, label = "" }) {
  var {
    data: status,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllSpedisciOnlineStatusQuery();

  var selectedValue = status.find((s) => s.spedisci_online_status == defaultValue);
  return isLoading ? (
    <LoadingIcon size={"h-6 w-6"} color={"text-black"} />
  ) : isSuccess ? (
    <div className="flex-col">
        {label === "" ? <></> : <span className="block text-sm font-medium leading-6 text-gray-900">
            Stati Spedizione
        </span>}
      <Select
        placeholder={"Cerca Stato Spedizione..."}
        isSearchable={isSearchable}
        options={status.map((status) => ({ id: status.id, label: status.spedisci_online_status, value: status }))}
        value={selectedValue === undefined ? null : { id: selectedValue.id, label: selectedValue.spedisci_online_status, value: selectedValue }}
        defaultValue={selectedValue === undefined ? null : { id: selectedValue.id, label: selectedValue.spedisci_online_status, value: selectedValue }}
        onChange={(value) => onChange(value)}
        isClearable={true}
      />
    </div>
  ) : (
    <></>
  );
}

export default DropDownShipmentStatus;
