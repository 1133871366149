import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { SearchIcon } from "@heroicons/react/outline";
import { HandlingStatusEnum } from "../../enums/HandlingStatusEnum";
import ToggleButton from "../../components/custom/ToggleButton";
import DropDownStore from "../../components/DropDownStore";
import { DropDownCustomer } from "../../components/customer/DropDownCustomer";
import { debounce } from "@mui/material";
import { useGetAllShipmentsOnHoldQuery } from "../../services/shipmentOnHoldApi";
import ListShipmentOnHold from "./components/ListShipmentOnHold";
import RadioGroupIcon from "../../components_used/RadioGroupIcon";
import { TableLoading } from "../../components/shipment/LoadingTable";
import { PaginationList } from "../../components/catalog/PaginationList";
import UserRoleEnum from "../../enums/UserRoleEnum";
import { DatePickerBasic } from "../../components_used/DatePickerBasic";


const options = [
  { id: 0, name: "Tutto" },
  { id: 1, name: "Si" },
  { id: 2, name: "No" },
];


export const ShipmentOnHoldPage = () => {
  const auth = useSelector((state) => state.auth);

  const handlingStatusRadioButtons = [
    {
      id: HandlingStatusEnum.DA_LAVORARE.id,
      name: "Da Gestire"
    },
    {
      id: HandlingStatusEnum.SVINCOLO_EFFETTUATO.id,
      name: HandlingStatusEnum.SVINCOLO_EFFETTUATO.name
    },
    {
      id: HandlingStatusEnum.SVINCOLO_NON_POSSIBILE.id,
      name: HandlingStatusEnum.SVINCOLO_NON_POSSIBILE.name
    },
    {
      id: HandlingStatusEnum.IN_LAVORAZIONE.id,
      name: auth.roles.some((r) => r === UserRoleEnum.AMMINISTRATORE) ? "Non Gestiti Da Clienti" : "In Gestione A CodFacile"
    }
  ]
  const [showOrderProducts, setShowOrderProducts] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    rowsPage: 20
  })
  const [filter, setFilter] = useState({
    searchText: "",
    isCashOnDelivery: options[0].id,
    isPaidOrder: options[0].id,
    isPrinted: options[0].id,
    dateRange: { startDate: moment().add(-30, "days").format("yyyy-MM-DD"), endDate: moment().format("yyyy-MM-DD") },
    shopifyStoreId: null,
    codfacileCustomer: null,
    handlingStatus: HandlingStatusEnum.DA_LAVORARE,
  })

  const { data: shipmentsOnHold, isLoading, isSuccess } = useGetAllShipmentsOnHoldQuery({
    filter,
    page: pagination.page,
    rowsPage: pagination.rowsPage,
  }, {
    refetchOnMountOrArgChange: true
  });

  return (
    <main className="px-4">
      <div className="flex-col mb-2">
        <div className="flex flex-wrap gap-2 items-end mb-2">
          <div className="flex gap-2 mt-2 flex-wrap items-end">
            <div className="flex-col ">
              <DropDownStore onChange={(store) => setFilter({ ...filter, shopifyStoreId: store?.value?.id })} />
            </div>
            {auth.roles.some((r) => r === UserRoleEnum.AMMINISTRATORE) && (
              <div className="flex-col">
               <DropDownCustomer isSearchable={true} selectedValue={filter.codfacileCustomer} onSelectedValue={(customer) => setFilter({ ...filter, codfacileCustomer: customer?.value})}/>
              </div>
            )}
            <DatePickerBasic label={"Data Inizio"} value={filter.dateRange.startDate} onChange={(e) => setFilter({ ...filter, dateRange: { ...filter.dateRange, startDate: e.target.value } }) } />
            <DatePickerBasic label={"Data Fine"} value={filter.dateRange.endDate} onChange={(e) => setFilter({ ...filter, dateRange: { ...filter.dateRange, endDate: e.target.value } }) } />
          </div>
          <div className="flex items-center gap-2 flex-1">
            <input
              onChange={debounce((e) => setFilter({ ...filter, searchText: e.target.value }), 300)}
              onKeyDown={(e) => setFilter({ ...filter, searchText: e.target.value })}
              type="text"
              name="name"
              id="name"
              className="block w-full rounded-xl border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
              placeholder="Cerca nelle giacenze"
            />
          </div>
        </div>
        <div className="flex gap-3">
            <RadioGroupIcon
              defaultItem={filter.handlingStatus}
              items={handlingStatusRadioButtons}
              onChange={(item) => setFilter({ ...filter, handlingStatus: item })}
            />
          <ToggleButton
            enabled={showOrderProducts}
            onChangeEvent={(e) => {
              setShowOrderProducts(e);
            }}
            text={"Mostra Prodotti nell'ordine"}
          />
        </div>
      </div>
      <PaginationList numOrders={shipmentsOnHold?.totalCount ?? 0} pagination={pagination} setPagination={setPagination} />
      {isLoading ? <TableLoading /> : isSuccess && <ListShipmentOnHold shipmentOnHolds={shipmentsOnHold?.shipments} showOrderProducts={showOrderProducts}/>}
    </main>
  );
};
