import React, { useEffect, useState } from 'react'
import { LoadingIcon } from '../custom/LoadingIcon'
import { TableLoading } from '../shipment/LoadingTable'
import CellRowTable from '../custom/CellRowTable'
import HeaderColTable from '../custom/HeaderColTable'
import HeaderTable from '../custom/HeaderTable'
import ListTable from '../custom/ListTable'
import RowTable from '../custom/RowTable'
import BodyTable from '../custom/BodyTable'
import {  useGetAllEbayIntegrationQuery, useUpdateEbayIntegrationMutation } from '../../services/ebayIntegrationApi'
import HiddenHeaderColTable from '../custom/HiddenHeaderColTable'
import ButtonTable from '../custom/ButtonTable'
import { PencilIcon, TrashIcon } from '@heroicons/react/solid'
import { InputField } from '../../components_used/InputField'

function EbayList() {
  const  { data: ebayIntegrations, isLoading, isSuccess, isError, error, refetch } = useGetAllEbayIntegrationQuery()
  const [updateEbayIntegration, { isLoading: loadingUpdate, isSuccess: successUpdate, isError: errorUpdate, error: errorTextUpdate }] = useUpdateEbayIntegrationMutation()
  const [integrationToEdit, setIntegrationToEdit] = useState({})

  const onUpdateClick = async () => {
    updateEbayIntegration(integrationToEdit).finally(() => {
      setIntegrationToEdit({})
      refetch()
    })
  }
  
  // useEffect(() =>{
  //   getAllEbayIntegration()
  // }, [])

  return (
    isLoading ? <TableLoading /> :  <ListTable className="mt-2">
    <HeaderTable>
      <RowTable>
        <HeaderColTable>Store</HeaderColTable>
        <HeaderColTable>Scadenza</HeaderColTable>
        <HiddenHeaderColTable />
      </RowTable>
    </HeaderTable>
    <BodyTable>
      {ebayIntegrations?.map((ebay, index) => (
        <RowTable key={index}>
          <CellRowTable> {ebay.id == integrationToEdit.id ?  <InputField value={integrationToEdit.storeName} onChange={(e) => setIntegrationToEdit({...ebay, storeName: e.target.value})} onEnter={() => onUpdateClick()} /> : ebay.storeName}</CellRowTable>
          <CellRowTable>{ebay.refreshTokenExpirationDate}</CellRowTable>
          <CellRowTable><div className="flex gap-2">
                  <ButtonTable
                    Icon={PencilIcon}
                    color={
                      "from-green-400 via-green-500 to-green-600 focus:ring-green-300 dark:focus:ring-green-800"
                    }
                    onClick={() => {setIntegrationToEdit(ebay)}}
                  />
                </div></CellRowTable>
        </RowTable>
      ))}
    </BodyTable>
  </ListTable>
  )
}

export default EbayList