import React, { useState } from 'react'
import { useGetLogisticsStockPriceListQuery, useAddLogisticsStockPriceListMutation, useEditLogisticsStockPriceListMutation, useDeleteLogisticsStockPriceListMutation, useAddLogisticsStockPriceMutation } from '../../services/logisticsStockPriceListApi'
import useHandleMutationResponse from '../../hooks/useHandleMutationResponse'
import { TableLoading } from '../shipment/LoadingTable'
import ListTable from '../custom/ListTable'
import HeaderTable from '../custom/HeaderTable'
import RowTable from '../custom/RowTable'
import HeaderColTable from '../custom/HeaderColTable'
import HiddenHeaderColTable from '../custom/HiddenHeaderColTable'
import BodyTable from '../custom/BodyTable'
import CellRowTable from '../custom/CellRowTable'
import ButtonTable from '../custom/ButtonTable'
import { CheckIcon, PencilIcon, PlusIcon, TrashIcon, XIcon } from '@heroicons/react/outline'
import SimpleNotification from '../SimpleNotification'
import ModalConfirmationRequest from '../custom/ModalConfirmationRequest'
import { NextActionEnum } from '../../enums/NextActionEnum'
import EditLogisticsStockPricePopup from './EditLogisticsStockPriceList'
import { CheckCircleIcon } from '@heroicons/react/solid'


function LogisticsStockPriceList() {

    const [ mutationResponse, setMutationResponse ] = useState({
        message: "",
        show: false,
        success: false        
    })
    const [showConfirmDialog, setShowConfirmDialog ] = useState({
      handleConfirm: null,
      message: "",
      showModal: false
    })
    const [showEditPopup, setShowEditPopup] = useState(false)
    const [selectedLogisticsStockPriceList, setSelectedLogisticsStockPriceList] = useState(null)
    const { data: logisticsStockPriceLists, isLoading: loadingLogisticsStockPriceLists, isSuccess: loadedLogisticsStockPriceLists, isError: isErrorLoadingLogisticsStockPriceLists, error: errorLoadingLogisticsStockPriceLists } = useGetLogisticsStockPriceListQuery()
    const [ addLogisticsStockPriceList, { isLoading: addingLogisticsStockPriceList, isSuccess: addedLogisticsStockPriceList, isError: isErrorAddingLogisticsStockPriceList , error: errorAddingLogisticsStockPriceList }] = useAddLogisticsStockPriceListMutation()
    const [ editLogisticsStockPriceList, { isLoading: editingLogisticsStockPriceList, isSuccess: editedLogisticsStockPriceList, isError: isErrorEditingLogisticsStockPriceList, error: errorEditingLogisticsStockPriceList }] = useEditLogisticsStockPriceListMutation()
    const [ deleteLogisticsStockPriceList, { isLoading: deletingLogisticsStockPriceList, isSuccess: deletedLogisticsStockPriceList, isError: isErrorDeletingLogisticsStockPriceList, error: errorDeletingLogisticsStockPriceList }] = useDeleteLogisticsStockPriceListMutation()
    const [ addLogisticsStockPrice ] = useAddLogisticsStockPriceMutation()

    useHandleMutationResponse({
        successEvent: addedLogisticsStockPriceList,
        errorEvent: isErrorAddingLogisticsStockPriceList,
        successMessage: "Logistics Stock Price List Added Successfully",
        errorMessage: errorAddingLogisticsStockPriceList,
        setMutationResponse,
        dependencies: [addingLogisticsStockPriceList],
    });
    useHandleMutationResponse({
        successEvent: editedLogisticsStockPriceList,
        errorEvent: isErrorEditingLogisticsStockPriceList,
        successMessage: "Logistics Stock Price List Edited Successfully",
        errorMessage: errorEditingLogisticsStockPriceList,
        setMutationResponse,
        dependencies: [editingLogisticsStockPriceList],
    });
    useHandleMutationResponse({
        successEvent: deletedLogisticsStockPriceList,
        errorEvent: isErrorDeletingLogisticsStockPriceList,
        successMessage: "Logistics Stock Price List Deleted Successfully",
        errorMessage: errorDeletingLogisticsStockPriceList,
        setMutationResponse,
        dependencies: [deletingLogisticsStockPriceList],
    });

    const handleAddPricesClick = async (logisticsStockPriceList) => {
        if(logisticsStockPriceList?.logisticsStockPriceListPrices?.length > 0){
            setSelectedLogisticsStockPriceList(logisticsStockPriceList)
            setShowEditPopup(true)
            return;            
        }
        const prices = Object.values(NextActionEnum).map(action => ({
            action,
            price: 0, // Default price, you can change this as needed,
            shipmentValuePercentage: 0
        }))
        await addLogisticsStockPrice({ id: logisticsStockPriceList.id, prices })
        setSelectedLogisticsStockPriceList(logisticsStockPriceList)
        setShowEditPopup(true)
    }

    return (
        <main>
            <ModalConfirmationRequest message={showConfirmDialog.message} handleConfirm={showConfirmDialog.handleConfirm} showModal={showConfirmDialog.showModal} setShowModal={(show) => setShowConfirmDialog({...showConfirmDialog, showModal: show})}/>
            <SimpleNotification message={mutationResponse.message} show={mutationResponse.show} setShow={() => setMutationResponse({...mutationResponse, show: false})} success={mutationResponse.success} />
            {loadingLogisticsStockPriceLists ? (
                <TableLoading />
            ) : loadedLogisticsStockPriceLists ? (
                <ListTable>
                    <HeaderTable>
                        <RowTable>
                            <HeaderColTable>Nome</HeaderColTable>
                            <HeaderColTable className={"text-center"}>PREDEFINITO</HeaderColTable>
                            <HeaderColTable className={"text-center"}>PREMIUM</HeaderColTable>
                            <HiddenHeaderColTable />
                        </RowTable>
                    </HeaderTable>
                    <BodyTable>
                        {logisticsStockPriceLists.map((list) => (
                            <RowLogisticsStockPriceList key={list.id} logisticsStockPriceList={list} onEditClick={(logisticsStockPriceList) => editLogisticsStockPriceList({ id: logisticsStockPriceList.id, logisticsStockPriceListEdited: logisticsStockPriceList })} onDeleteClick={(logisticsStockPriceList) => {
                                setShowConfirmDialog({
                                    handleConfirm: () => {
                                        deleteLogisticsStockPriceList(logisticsStockPriceList.id).finally(() => setShowConfirmDialog({ ...showConfirmDialog, showModal: false }))
                                    },
                                    message: `Confermi di voler eliminare il listino '${logisticsStockPriceList.name}'?`,
                                    showModal: true
                                })
                            }} onAddPricesClick={handleAddPricesClick}/>
                        ))}
                    </BodyTable>
                </ListTable>
            ) : isErrorLoadingLogisticsStockPriceLists ? (
                <p>{errorLoadingLogisticsStockPriceLists}</p>
            ) : (
                <></>
            )}
            <button
                disabled={false}
                onClick={() => {
                    addLogisticsStockPriceList({ name: "", isDefault: false, isPremium: false })
                }}
                type="submit"
                className="mt-2 w-full justify-center bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 inline-flex items-center gap-x-1.5 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
            >
                <PlusIcon className="h-4 w-4" />
                Aggiungi Listino Giacenza
            </button>
            {showEditPopup && (
                <EditLogisticsStockPricePopup
                    logisticsStockPriceList={selectedLogisticsStockPriceList}
                    setOpen={setShowEditPopup}
                />
            )}
        </main>
    )
}

export default LogisticsStockPriceList

function RowLogisticsStockPriceList({ logisticsStockPriceList, onEditClick, onDeleteClick, onAddPricesClick }) {
    const [editMode, setEditMode] = useState({
        isActive: false,
        valueToEdit: undefined
    })
    return (
        <RowTable key={logisticsStockPriceList.id}>
            <CellRowTable>{editMode.isActive ? <input className='border-solid border border-gray-200 rounded-md pl-1' value={editMode?.valueToEdit?.name} onChange={(e) => setEditMode({ ...editMode, valueToEdit: { ...editMode.valueToEdit, name: e.target.value }})} /> : logisticsStockPriceList.name}</CellRowTable>
            <CellRowTable><div className='flex justify-center'>{editMode.isActive ? <input type="checkbox" checked={editMode?.valueToEdit?.isDefault} onChange={(e) => setEditMode({ ...editMode, valueToEdit: { ...editMode.valueToEdit, isDefault: e.target.checked }})} /> : logisticsStockPriceList.isDefault ? <CheckCircleIcon className='h-5 w-5' /> : ""}</div></CellRowTable>
            <CellRowTable><div className='flex justify-center'>{editMode.isActive ? <input type="checkbox" checked={editMode?.valueToEdit?.isPremium} onChange={(e) => setEditMode({ ...editMode, valueToEdit: { ...editMode.valueToEdit, isPremium: e.target.checked }})} /> : logisticsStockPriceList.isPremium ? <CheckCircleIcon className='h-5 w-5' /> : ""}</div></CellRowTable>
            <CellRowTable>
                <div className="flex gap-2">
                    <ButtonTable
                        Icon={editMode.isActive ? CheckIcon : PencilIcon}
                        color={
                            "from-green-400 via-green-500 to-green-600 focus:ring-green-300 dark:focus:ring-green-800"
                        }
                        onClick={() => {
                            if(!editMode.isActive){
                                setEditMode({ isActive: true, valueToEdit: logisticsStockPriceList })
                                return
                            }
                            onEditClick(editMode?.valueToEdit)
                            setEditMode({ isActive: false, valueToEdit: null })
                        }}
                    />
                    <ButtonTable
                        Icon={editMode.isActive ? XIcon : TrashIcon}
                        color={
                            "from-red-400 via-red-500 to-red-600 focus:ring-red-300 dark:focus:ring-red-800"
                        }
                        onClick={() => {
                            if(editMode.isActive){
                                setEditMode({ isActive: false, valueToEdit: null })
                                return;
                            }
                            onDeleteClick(logisticsStockPriceList)
                        }}
                    />
                    <ButtonTable
                        Icon={PlusIcon}
                        withTooltip={true}
                        tooltipText={"Aggiungi prezzi"}
                        color={
                            "from-blue-400 via-blue-500 to-blue-600 focus:ring-blue-300 dark:focus:ring-blue-800"
                        }
                        onClick={() => onAddPricesClick(logisticsStockPriceList)}
                    />
                </div>
            </CellRowTable>
        </RowTable>
    )
}