import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSignUpMutation } from "../../services/authApi";
import { useDispatch } from "react-redux";
import { setAuth } from "../../features/auth/authSlice";
import { RegistrationForm } from "./components/RegistrationForm";
import { ErrorBanner } from "../../components/custom/ErrorBanner";
import axios from "axios";
import { getPublicClientIp } from "../../functions/ip_address";
//const { AddressValidationClient } = require("@googlemaps/addressvalidation").v1;

// Instantiates a client
export const RegistrationPage = () => {
  //const addressvalidationClient = new AddressValidationClient();
  const dispatch = useDispatch();
  const [signUp, { data, isLoading, isSuccess, isError, error }] =
    useSignUpMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/integration";
  const [registeredUser, setRegisteredUser] = useState({
    email: "",
    name: "",
    surname: "",
    password: "",
    confirmPassword: "",
    businessName: "",
    vatNumber: "",
    fiscalCode: "",
    address: "",
    civico: "",
    phone: "",
    city: "",
    zipCode: "",
    province: "",
    nation: "",
    iban: "",
    privacy: false,
    termsAndConditions: false,
    ipAddress: null
  })

  const [ mutationResponse, setMutationResponse ] = useState({
      message: "",
      show: false,
      success: false        
  })
  const { promotionCode } = useParams();

  useEffect(() => {
    if (isSuccess) {
      dispatch(setAuth(data));
      navigate(from, { replace: true });
    } else if (isError) {
      const { message } = error.data;
      setMutationResponse({
        message: message,
        show: true,
        success: false
      })
    }
  }, [isLoading]);

  const handleSignUp = async (e) => {
    e.preventDefault(e);
    if (registeredUser.password != registeredUser.confirmPassword) {
      setMutationResponse({
        message: "Le password non coincidono",
        show: true,
        success: false
      })
      return;
    }
    if ((registeredUser.name?.trim() ?? "") == "") {
      setMutationResponse({
        message: "Inserisci un nome valido",
        show: true,
        success: false
      })
      return;
    }
    if ((registeredUser.civico?.trim() ?? "") == "") {
      setMutationResponse({
        message: "Inserisci un civico valido",
        show: true,
        success: false
      })
      return;
    }

    const ipAddress = await getPublicClientIp()
    await signUp({ ...registeredUser, ipAddress: ipAddress });
  };

  return (
    <section className="h-screen">
      <div className="flex flex-wrap justify-center h-screen">
        <div className="lg:basis-1/2">
          <RegistrationForm
            promotionCode={promotionCode}
            isLoading={isLoading}
            handleSignUp={handleSignUp}
            registeredUser={registeredUser}
            setRegisteredUser={setRegisteredUser}
          />
        </div>
        <div
          style={{
            backgroundImage:
              "url('https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80')",
          }}
          className="hidden lg:block lg:basis-1/2"
        ></div>
      </div>
      <ErrorBanner
        message={mutationResponse.message}
        show={mutationResponse.show}
        setShow={(showState) => setMutationResponse({ ...mutationResponse, show: showState })}
      />
    </section>
  );
};
