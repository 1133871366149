import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions, Radio, RadioGroup } from "@headlessui/react";
import { CheckCircleIcon, CheckIcon } from "@heroicons/react/outline";
import React from "react";
import useWindowDimensions from "../hooks/useWindowsDimensions";
import { ChevronDownIcon } from "@heroicons/react/solid";

function RadioGroupIcon({ items, onChange, defaultItem }) {
  const { width } = useWindowDimensions();

  return width > 1176 ?
      <RadioGroup
        value={defaultItem}
        onChange={onChange}
        //onChange={(value) =>  setShipmentOnHold({ ...shipmentOnHold, nextAction: value, handlingCost: value.price == 0 && value.shipmentValuePercentage == 0 ? 0 : shippingPriceCustomer + value.price + (value.shipmentValuePercentage > 0 ? (shippingPriceCustomer * (value.shipmentValuePercentage / 100)) : 0)})}
        className="flex gap-3 flex-wrap"
      >
        {items?.map((item) => (
          <Radio
            key={item.id}
            value={item}
            className="group relative flex gap-2 flex-wrap cursor-pointer rounded-lg border border-gray-300 bg-white p-2 shadow-sm focus:outline-none data-[focus]:border-green-600 data-[focus]:ring-2 data-[focus]:ring-green-600"
          >
            <span className="flex flex-1">
              <span className="flex flex-col">
                <span className="block text-sm font-medium text-gray-900">{item.name}</span>
              </span>
            </span>
            <CheckCircleIcon
              aria-hidden="true"
              className="size-5 text-green-600 group-[&:not([data-checked])]:invisible"
            />
            <span
              aria-hidden="true"
              className="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent group-data-[focus]:border group-data-[checked]:border-green-600"
            />
          </Radio>
        ))}
      </RadioGroup> :
          <Listbox value={defaultItem} onChange={onChange}>
          <div className="relative mt-2">
            <ListboxButton className="grid w-full cursor-default grid-cols-1 rounded-md bg-white py-1.5 pl-3 pr-2 text-left text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6">
              <span className="col-start-1 row-start-1 truncate pr-6">{defaultItem.name}</span>
              <ChevronDownIcon
                aria-hidden="true"
                className="col-start-1 row-start-1 size-5 self-center justify-self-end text-gray-500 sm:size-4"
              />
            </ListboxButton>
    
            <ListboxOptions
              transition
              className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
            >
              {items.map((item) => (
                <ListboxOption
                  key={item.id}
                  value={item}
                  className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-green-600 data-[focus]:text-white data-[focus]:outline-none"
                >
                  <span className="block truncate font-normal group-data-[selected]:font-semibold">{item.name}</span>
    
                  <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-green-600 group-[&:not([data-selected])]:hidden group-data-[focus]:text-white">
                    <CheckIcon aria-hidden="true" className="size-5" />
                  </span>
                </ListboxOption>
              ))}
            </ListboxOptions>
          </div>
        </Listbox>
  
}

export default RadioGroupIcon;
