import React, { useEffect, useState } from "react";

function SearchBar({ onChange, placeholder }) {
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    onChange(searchText);
  }, [searchText]);

  return (
    <div className="mt-2">
      <input
        type="text"
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
        id="search-customer"
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-2 "
        placeholder={placeholder}
      />
    </div>
  );
}

export default SearchBar;
