import React from 'react';
import PropTypes from 'prop-types';

const BadgeShipmentStatus = ({ status }) => {
    return (
        <span
            className={`inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium w-fit ${
                status === "Spedizione non trovata"
                    ? "text-red-700 bg-red-100"
                    : status.toLowerCase().includes("consegnata")
                    ? "text-green-700 bg-green-100"
                    : "text-yellow-800 bg-yellow-100"
            }`}
        >
            <svg
            className={`h-1.5 w-1.5 ${
                status == "Spedizione non trovata"
                ? "fill-red-500"
                : status
                    .toLowerCase()
                    .includes("consegnata")
                ? "fill-green-500"
                : "fill-yellow-500"
            } `}
            viewBox="0 0 6 6"
            aria-hidden="true"
            >
            <circle cx={3} cy={3} r={3} />
            </svg>
            {status}
        </span>
    );
};

BadgeShipmentStatus.propTypes = {
    status: PropTypes.string.isRequired,
};

export default BadgeShipmentStatus;