export const InputField = ({
    id,
    inputRef,
    autofocus = false,
    label,
    type = "text",
    onChange,
    required = true,
    autoComplete,
    value,
    step,
    disabled = false,
    placeholder,
    onEnter,
    maxWidth 
  }) => {
    function enterPressed(event) {
      var code = event.keyCode || event.which;
      if (code === 13) {
        //13 is the enter keycode
        onEnter && onEnter();
      }
    }
    return (
      <div className={`flex-1 ${maxWidth && maxWidth}`}>
        <div className="flex items-center justify-between">
          <label
            htmlFor={id}
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {label}
          </label>
        </div>
        {/* <div className="mt-2"> */}
        <div>
          {value == undefined ? (
            <input
              ref={inputRef}
              autoFocus={autofocus}
              placeholder={placeholder ?? ""}
              disabled={disabled}
              onChange={onChange}
              onKeyDown={enterPressed.bind(this)}
              id={id}
              name={id}
              type={type}
              autoComplete={autoComplete}
              required={required}
              className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-facile sm:text-sm sm:leading-6"
              step={step}
            />
          ) : (
            <input
              ref={inputRef}
              autoFocus={autofocus}
              placeholder={placeholder ?? ""}
              disabled={disabled}
              value={value}
              onChange={onChange}
              onKeyDown={enterPressed.bind(this)}
              id={id}
              name={id}
              type={type}
              autoComplete={autoComplete}
              required={required}
              step={step}
              className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-facile sm:text-sm sm:leading-6"
            />
          )}
        </div>
      </div>
    );
  };