import Cookies from "js-cookie";
export const getToken = (cookieName) => {
  return Cookies.get(cookieName);
};

export const setTokenSecure = (cookieName, cookieValue) => {
  return Cookies.set(cookieName, cookieValue, { expires: 7, secure: true });
};

export const deleteToken = (cookieName) => {
  return Cookies.remove(cookieName);
};
