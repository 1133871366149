import { useSelector } from "react-redux";
import { ManageShipmentOnHoldPopupUser } from "./ManageShipmentOnHoldPopupUser";
import { ManageShipmentOnHoldPopupAdmin } from "./ManageShipmentOnHoldPopupAdmin";
import { useState } from "react";

export function ManageShipmentOnHoldButton({ nextAction, handlingCost, onClickButton }){

  return <main>
    <button
      onClick={onClickButton}
      type="button"
      className="rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
    >
      {`${nextAction?.toUpperCase() ?? 'Gestisci Giacenza'}${handlingCost !== null ? ` (${handlingCost}€)` : ''}`}
    </button>
</main>

}