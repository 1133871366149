import { Fragment, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { useUpdateCustomerMutation } from "../../services/customerApi";
import DropDownShippingPriceList from "../shipment/ShippingPriceList/DropDownShippingPriceList";
import { InputField } from "../../components_used/InputField";
import DropDownConfirmOrderPriceList from "../DropDownConfirmOrderPriceList";
import { DropDownLogisticsStockPriceList } from "../logistics/DropDownLogisticsStockPriceList";

export default function EditCustomerPopup({
  customer,
  setCustomer,
  open,
  setOpen,
}) {
  const cancelButtonRef = useRef(null);

  const [updateCustomer, { isLoading, isSuccess, isError, error }] =
    useUpdateCustomerMutation();

  const handleEditButton = async (e) => {
    e.preventDefault();
    await updateCustomer({ customer });
    setOpen(false);
  };

  const handleChangeShippingPriceList = (shippingPriceList) => {
    setCustomer({
      ...customer,
      company: {
        ...customer.company,
        shippingPriceList: shippingPriceList,
      },
    });
  };
  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform w-full overflow-y-auto rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:p-6">
                <div>
                  <div className="mt-2 text-center sm:mt-4">
                    <DialogTitle
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Cliente
                    </DialogTitle>
                    <div className="mt-1">
                      <form
                        className="space-y-6"
                        onSubmit={(e) => handleEditButton(e)}
                      >
                        <p className="text-2xl font-bold m-0">Modifica</p>
                        <div className="flex flex-wrap gap-3">
                          <div className="flex flex-col flex-1">
                            {" "}
                            <div className="sm:flex gap-5">
                              <div className="flex-1">
                                <label
                                  htmlFor="code-new-shipping-price-list"
                                  className="text-left block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Nome
                                </label>
                                <div className="mt-2">
                                  <input
                                    onChange={(e) =>
                                      setCustomer({
                                        ...customer,
                                        name: e.target.value,
                                      })
                                    }
                                    value={
                                      customer == null ? "" : customer.name
                                    }
                                    id="code-new-shipping-price-list"
                                    name="code-new-shipping-price-list"
                                    type="text"
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-facile sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                              <div className="flex-1">
                                <div className="flex items-center justify-between">
                                  <label
                                    htmlFor="description-new-shipping-price-list"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                  >
                                    Cognome
                                  </label>
                                  <div className="text-sm"></div>
                                </div>
                                <div className="mt-2">
                                  <input
                                    onChange={(e) =>
                                      setCustomer({
                                        ...customer,
                                        surname: e.target.value,
                                      })
                                    }
                                    value={
                                      customer == null ? "" : customer.surname
                                    }
                                    id="description-new-shipping-price-list"
                                    name="description-new-shipping-price-list"
                                    type="text"
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-facile sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>{" "}
                            <InputField
                              autoComplete={false}
                              value={customer?.company?.phone ?? ""}
                              onChange={(e) =>
                                setCustomer({
                                  ...customer,
                                  company: {
                                    ...customer.company,
                                    phone: e.target.value,
                                  },
                                })
                              }
                              type="text"
                              label={"Telefono"}
                            />
                            <div className="flex justify-start gap-2 ">
                              <div className="flex gap-2">
                                <input
                                  type="checkbox"
                                  id="needs-subscription"
                                  name="needs-subscription"
                                  checked={customer?.needsSubscription ?? false}
                                  onChange={(e) => {
                                    setCustomer({
                                      ...customer,
                                      needsSubscription: e.target.checked,
                                    });
                           
                                  }}
                                />
                                <label htmlFor="needs-subscription">
                                  Abbonamento Necessario?
                                </label>
                              </div>
                              <div className="flex gap-2">
                                <input
                                  type="checkbox"
                                  id="must-pay-for-orders"
                                  name="must-pay-for-orders"
                                  checked={customer?.mustPayForOrders ?? false}
                                  onChange={(e) => {
                                    setCustomer({
                                      ...customer,
                                      mustPayForOrders: e.target.checked,
                                    });
                                
                                  }}
                                />
                                <label htmlFor="must-pay-for-orders">
                                  Deve Pagare Gli Ordini PAGATI?
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col flex-1">
                            <div className="flex-1 ">
                              <div className="flex items-center justify-between">
                                <label
                                  htmlFor="shipping-cost-new-shipping-price-list"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Società
                                </label>
                                <div className="text-sm"></div>
                              </div>
                              <div className="mt-2">
                                <input
                                  onChange={(e) =>
                                    setCustomer({
                                      ...customer,
                                      company: {
                                        ...customer.company,
                                        businessName: e.target.value,
                                      },
                                    })
                                  }
                                  value={
                                    customer == null
                                      ? ""
                                      : customer.company.businessName
                                  }
                                  id="shipping-cost-new-shipping-price-list"
                                  name="shipping-cost-new-shipping-price-list"
                                  type="text"
                                  className="block w-full rounded-md border-0 py-1.5 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-facile sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                            <InputField
                              autoComplete={false}
                              value={customer?.company?.iban ?? ""}
                              onChange={(e) =>
                                setCustomer({
                                  ...customer,
                                  company: {
                                    ...customer.company,
                                    iban: e.target.value,
                                  },
                                })
                              }
                              type="text"
                              label={"IBAN"}
                            />
                            <div className="sm:flex gap-5">
                              <div className="flex-1 ">
                                <div className="flex items-center justify-between">
                                  <label
                                    htmlFor="shipping-cost-new-shipping-price-list"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                  >
                                    Partita Iva
                                  </label>
                                  <div className="text-sm"></div>
                                </div>
                                <div className="mt-2">
                                  <input
                                    onChange={(e) =>
                                      setCustomer({
                                        ...customer,
                                        company: {
                                          ...customer.company,
                                          vatNumber: e.target.value,
                                        },
                                      })
                                    }
                                    value={
                                      customer == null
                                        ? ""
                                        : customer.company.vatNumber
                                    }
                                    id="shipping-cost-new-shipping-price-list"
                                    name="shipping-cost-new-shipping-price-list"
                                    type="text"
                                    className="block w-full rounded-md border-0 py-1.5 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-facile sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>

                              <div className="flex-1 ">
                                <div className="flex items-center justify-between">
                                  <label
                                    htmlFor="shipping-cost-new-shipping-price-list"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                  >
                                    Codice Fiscale
                                  </label>
                                  <div className="text-sm"></div>
                                </div>
                                <div className="mt-2">
                                  <input
                                    onChange={(e) =>
                                      setCustomer({
                                        ...customer,
                                        company: {
                                          ...customer.company,
                                          fiscalCode: e.target.value,
                                        },
                                      })
                                    }
                                    value={
                                      customer == null
                                        ? ""
                                        : customer.company.fiscalCode
                                    }
                                    id="shipping-cost-new-shipping-price-list"
                                    name="shipping-cost-new-shipping-price-list"
                                    type="text"
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-facile sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <InputField
                          autoComplete={false}
                          value={customer?.company?.address ?? ""}
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              company: {
                                ...customer.company,
                                address: e.target.value,
                              },
                            })
                          }
                          type="text"
                          label={"Indirizzo"}
                        />
                        <div className="sm:flex gap-5">
                          <InputField
                            autoComplete={false}
                            value={customer?.company?.city ?? ""}
                            onChange={(e) =>
                              setCustomer({
                                ...customer,
                                company: {
                                  ...customer.company,
                                  city: e.target.value,
                                },
                              })
                            }
                            type="text"
                            label={"Città"}
                          />
                          <InputField
                            autoComplete={false}
                            value={customer?.company?.province ?? ""}
                            onChange={(e) =>
                              setCustomer({
                                ...customer,
                                company: {
                                  ...customer.company,
                                  province: e.target.value,
                                },
                              })
                            }
                            type="text"
                            label={"Provincia"}
                          />
                          <InputField
                            autoComplete={false}
                            value={customer?.company?.zipCode ?? ""}
                            onChange={(e) =>
                              setCustomer({
                                ...customer,
                                company: {
                                  ...customer.company,
                                  zipCode: e.target.value,
                                },
                              })
                            }
                            type="text"
                            label={"Codice Postale"}
                          />
                        </div>
                        <InputField
                          autoComplete={false}
                          value={customer?.pwd ?? ""}
                          disabled={true}
                          onChange={() => {}}
                          type="text"
                          label={"Password"}
                        />

                        <DropDownConfirmOrderPriceList
                          //selectedValue={filterPriceList}
                          selectedValue={
                            customer?.company?.confirmOrderPriceList ?? {
                              id: 0,
                              name: "Nessuno",
                            }
                          }
                          // onSelectedPriceList={() => {}}
                          onSelectedPriceList={(priceList) =>
                            setCustomer({
                              ...customer,
                              company: {
                                ...customer.company,
                                confirmOrderPriceList: priceList,
                              },
                            })
                          }
                        />
                        <div className="flex gap-1 m-0">
                          <input
                            checked={customer?.confirmOrderEnable ?? false}
                            type="checkbox"
                            onChange={(e) =>
                              setCustomer({
                                ...customer,
                                confirmOrderEnable: e.target.checked,
                              })
                            }
                          />{" "}
                          <span>Abilita conferma ordine</span>{" "}
                        </div>
                        <DropDownShippingPriceList
                          selected={
                            customer == undefined
                              ? null
                              : customer.company.shippingPriceList
                          }
                          handleChange={handleChangeShippingPriceList}
                        />
                       <DropDownLogisticsStockPriceList 
                          onSelectedValue={(value) => setCustomer({ ...customer, company: { ...customer.company, logisticsStockPriceListId: value.id } })} 
                          selectedValue={customer?.company?.logisticsStockPriceList} />
                        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                          <button
                            type="submit"
                            className="inline-flex w-full justify-center rounded-md bg-green-facile px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-facile focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-facile sm:col-start-2"
                          >
                            Modifica
                          </button>
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                            onClick={() => setOpen(false)}
                            ref={cancelButtonRef}
                          >
                            Annulla
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
