import React from "react";
import { useGetAllCustomerQuery } from "../services/customerApi";
import { LoadingIcon } from "./custom/LoadingIcon";
import Select from "react-select";
import { useGetAllStoreQuery } from "../services/shopifyApi";

function DropDownStore({ onChange, isSearchable = false, openOnClick = false }) {
  var { stores, isLoading, isSuccess, isError, error } = useGetAllStoreQuery(
    undefined,
    {
      selectFromResult: ({ data, isLoading, isSuccess, isError, error }) => {
        var stores = data?.map((store) => ({
          value: store,
          label:
            (store?.aliasStore ?? "") == ""
              ? store?.storeName
              : store?.aliasStore,
        }));

        return {
          stores,
          isLoading,
          isSuccess,
          isError,
          error,
        };
      },
    }
  );
  return isLoading ? (
    <LoadingIcon size={"h-6 w-6"} color={"text-black"} />
  ) : isSuccess ? (
    <div className="flex-col ">
      <span className="block text-sm font-medium leading-6 text-gray-900">
        Negozio
      </span>

      <Select
        openMenuOnClick={openOnClick}
        placeholder={"Cerca Negozio..."}
        isSearchable={isSearchable}
        options={stores}
        onChange={(value) => onChange(value)}
        isClearable={true}
      />
    </div>
  ) : (
    <></>
  );
}

export default DropDownStore;
