import { Fragment, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  MenuIcon,
  CogIcon,
  DocumentDuplicateIcon,
  UsersIcon,
  XIcon,
  UserIcon,
  KeyIcon,
  TruckIcon,
  ArchiveIcon,
  PresentationChartBarIcon,
  ClipboardIcon,
  InboxIcon,
  TicketIcon,
  VideoCameraIcon,
  DocumentIcon,
  OfficeBuildingIcon,
  ArrowCircleLeftIcon,
  HomeIcon,
  StatusOnlineIcon,
  CreditCardIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import logo from "../images/logo_trasp.png";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLogOutMutation } from "../services/authApi";
import BalanceAmount from "../components/BalanceAmount";
import { Tooltip } from "@mui/material";
import { logout } from "../features/auth/authSlice";
import UserRoleEnum from "../enums/UserRoleEnum";
import { stripeBillingPortal } from "../config/baseUrl";

class MenuItem{  
  constructor(_name, _href, _icon, _userRolesLogged, _userRolesEnabled){
    this.name = _name;
    this.href = _href;
    this.icon = _icon;
    this.isVisible = _userRolesLogged.some((role) => _userRolesEnabled.some((enabledRole) => role === enabledRole))
  }
}

export default function SideBarMenu({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarOpenDesktop, setSidebarOpenesktop] = useState(false);

  const [logOut, result] = useLogOutMutation();
  const auth = useSelector((state) => state.auth);
  const location = useLocation();
  
  const dispatch = useDispatch();
  const [navigation, setNavigation] = useState([
    new MenuItem("Flusso di Cassa", "/cash-flow", PresentationChartBarIcon, auth.roles, [UserRoleEnum.AMMINISTRATORE, UserRoleEnum.USER]),
    new MenuItem("Ordini", "/orders", DocumentDuplicateIcon, auth.roles, [UserRoleEnum.AMMINISTRATORE, UserRoleEnum.OPERATORE_TELEFONICO, UserRoleEnum.USER ]),
    new MenuItem("Ordini Da Evadere", "/orders-to-fulfill", InboxIcon, auth.roles, [UserRoleEnum.AMMINISTRATORE]),
    new MenuItem("Spedizioni", "/shipment", TruckIcon, auth.roles, [UserRoleEnum.AMMINISTRATORE, UserRoleEnum.USER]),
    new MenuItem("Giacenze", "/shipment-on-hold", OfficeBuildingIcon, auth.roles, [UserRoleEnum.AMMINISTRATORE, UserRoleEnum.USER]),
    new MenuItem("Resi Al Mittente", "/returns-to-sender", ArrowCircleLeftIcon, auth.roles, [UserRoleEnum.AMMINISTRATORE]),
    new MenuItem("Inventario Clienti", "/customer-product-inventory", HomeIcon, auth.roles, [UserRoleEnum.AMMINISTRATORE, UserRoleEnum.USER]),
    new MenuItem("Catalogo", "/catalog", ClipboardIcon, auth.roles, [UserRoleEnum.AMMINISTRATORE, UserRoleEnum.OPERATORE_TELEFONICO, UserRoleEnum.USER]),
    new MenuItem("Clienti", "/customers", UsersIcon, auth.roles, [UserRoleEnum.AMMINISTRATORE]),
    new MenuItem("Rimessa Contrassegni", "/cod-remittance", ArchiveIcon, auth.roles, [UserRoleEnum.AMMINISTRATORE]),
    new MenuItem("Promozioni", "/promotion", TicketIcon, auth.roles, [UserRoleEnum.AMMINISTRATORE]),
    new MenuItem("Stati Spedizione", "/shipment_status", StatusOnlineIcon, auth.roles, [UserRoleEnum.AMMINISTRATORE]),
    new MenuItem("Integrazione", "/integration", KeyIcon, auth.roles, [UserRoleEnum.AMMINISTRATORE, UserRoleEnum.USER]),
    new MenuItem("Tutorial", "/tutorials", VideoCameraIcon, auth.roles, [UserRoleEnum.AMMINISTRATORE, UserRoleEnum.USER]),
    new MenuItem("Termini e Condizioni", "/termini-e-condizioni", DocumentIcon, auth.roles, [UserRoleEnum.AMMINISTRATORE, UserRoleEnum.USER]),
    new MenuItem("Gestisci Abbonamento", stripeBillingPortal, CreditCardIcon, auth.roles, [UserRoleEnum.USER]),
  ]);

  const handleLogOut = async () => {
    //deleteToken("token");
    await logOut();
    dispatch(logout({}));
    window.location.reload();
  };

  const userNavigation = [{ name: "Profilo", href: "/account" }];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <div>
        <Transition show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={() => setSidebarOpen(!sidebarOpen)}
          >
            <Transition
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition>

            <div className="fixed inset-0 flex">
              <Transition
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1">
                  <Transition
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition>
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-10 w-auto"
                        src={logo}
                        alt="Your Company"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.filter((nav) => nav.isVisible).map((item) => (
                              <li
                                key={item.name}
                                onClick={() => setSidebarOpen(false)}
                              >
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    item.href.substring(1) ===
                                      location.pathname.substring(1)
                                      ? "bg-gray-50 text-green-facile"
                                      : "text-gray-700 hover:text-green-facile hover:bg-gray-50",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.href.substring(1) ===
                                        location.pathname.substring(1)
                                        ? "text-green-facile"
                                        : "text-gray-400 group-hover:text-green-facile",
                                      "h-6 w-6 shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <ul>
                          <li className="mt-auto">
                            <button
                              onClick={handleLogOut}
                              className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-green-facile"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 text-gray-400 group-hover:text-green-facile"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75"
                                />
                              </svg>
                              Logout
                            </button>
                          </li>
                          {auth.roles.find((role) => role === 1) ? (
                            <li className="mt-auto">
                              <Link
                                to="/settings"
                                className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-green-facile"
                              >
                                <CogIcon
                                  className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-green-facile"
                                  aria-hidden="true"
                                />
                                Impostazioni
                              </Link>
                            </li>
                          ) : null}
                        </ul>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition>
            </div>
          </Dialog>
        </Transition>

        {/* Static sidebar for desktop */}
        <div
          className={`hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex ${
            sidebarOpenDesktop ? "lg:w-64" : "lg:w-16"
          } lg:flex-col overflow-y-auto h-full scrollbar-hide`}
        >
          <div className="flex grow flex-col gap-y-5  border-r border-gray-200 bg-white px-3 pb-4">
            {sidebarOpenDesktop ? (
              <Link
                autoFocus
                className="flex h-16 shrink-0 justify-end items-center"
                onClick={() => setSidebarOpenesktop(false)}
              >
                <XIcon className="h-6 w-6 text-gray-400 hover:text-green-facile hover:bg-gray-50" />
              </Link>
            ) : (
              <Link
                autoFocus
                className="flex h-16 shrink-0 justify-center items-center"
                onClick={() => setSidebarOpenesktop(true)}
              >
                <MenuIcon className="h-6 w-6 text-gray-400 hover:text-green-facile hover:bg-gray-50" />
              </Link>
            )}

            <nav className="flex flex-1 flex-col justify-between">
              {/* <ul
                role="list"
                className="flex flex-1 flex-col gap-y-7 items-center "
              >
                <li className={`${sidebarOpenDesktop ? "w-full" : ""}`}> */}
                  <ul role="list" className="-mx-2 space-y-1 ">
                    {navigation.filter((nav) => nav.isVisible).map((item) => (
                      <li
                        key={item.name}
                        onClick={() => setSidebarOpenesktop(false)}
                        className={`${sidebarOpenDesktop ? "w-full" : ""}`}
                      >
                        <Tooltip title={item.name} placement="right">
                          <Link
                            to={item.href}
                            className={classNames(
                              item.href.substring(1) ===
                                location.pathname.substring(1)
                                ? "bg-gray-50 text-green-facile"
                                : "text-gray-700 hover:text-green-facile hover:bg-gray-50",
                              `group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold ${sidebarOpenDesktop ? "" : "justify-center"}`
                            )}
                          >
                            <item.icon
                              className={classNames(
                                item.href.substring(1) ===
                                  location.pathname.substring(1)
                                  ? "text-green-facile"
                                  : "text-gray-400 group-hover:text-green-facile",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {sidebarOpenDesktop ? item.name : ""}
                          </Link>
                        </Tooltip>
                      </li>
                    ))}
                  </ul>
                {/* </li>
              </ul> */}
              <ul className={`flex flex-col  ${sidebarOpenDesktop ? "items-start" : "items-center"}`}>
                <li className="mt-auto">
                <Tooltip title={"Logout"} placement="right">
                  <button
                    onClick={handleLogOut}
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-green-facile"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 text-gray-400 group-hover:text-green-facile"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75"
                      />
                    </svg>

                    {sidebarOpenDesktop ? "Logout" : ""}
                  </button>
                  </Tooltip>
                </li>
                {auth.roles.find((role) => role === 1) ? (

                  <li className="mt-auto">
                     <Tooltip title={"Impostazioni"} placement="right">
                    <Link
                      to="/settings"
                      className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-green-facile"
                    >
                      <CogIcon
                        className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-green-facile"
                        aria-hidden="true"
                      />
                      {sidebarOpenDesktop ? "Impostazioni" : ""}
                    </Link>
                    </Tooltip>
                  </li>
                ) : null}
              </ul>
            </nav>
          </div>
        </div>

        <div className={`${sidebarOpenDesktop ? "lg:pl-64" : "lg:pl-16"}`}>
          <div className="sticky top-0 z-40 lg:mx-auto ">
            <div className="flex h-16 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-0 lg:shadow-none">
              <button
                type="button"
                className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </button>

              <div
                className="h-6 w-px bg-gray-200 lg:hidden"
                aria-hidden="true"
              />

              <div className="flex flex-1 justify-between items-center gap-x-4 self-stretch lg:gap-x-6 pl-4">
                <div>
                  <img
                    className="sm:block hidden h-12 w-auto"
                    src={logo}
                    alt="Your Company"
                  />
                </div>
                <div className="flex items-center gap-x-4 lg:gap-x-6">
                  {!auth.roles.some((r) => r === UserRoleEnum.AMMINISTRATORE || r === UserRoleEnum.OPERATORE_TELEFONICO) &&  <BalanceAmount /> }
                  <div
                    className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
                    aria-hidden="true"
                  />

                  <Menu as="div" className="relative">
                    <Menu.Button className="flex items-center p-1.5">
                      <span className="sr-only">Open user menu</span>
                      <div className="h-8 w-8 rounded-full bg-gray-500 flex justify-center items-center">
                        <UserIcon className="h-5 w-5 text-white" />
                      </div>
                      <span className="hidden lg:flex lg:items-center">
                        <ChevronDownIcon
                          className="ml-2 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item
                            key={item.name}
                            onClick={() => setSidebarOpen(false)}
                          >
                            {({ active }) => (
                              <Link
                                to={item.href}
                                className={classNames(
                                  active ? "bg-gray-50" : "",
                                  "block px-3 py-1 text-sm leading-6 text-gray-900"
                                )}
                              >
                                {item.name}
                              </Link>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <main>
            <div className="mb-20 mx-auto sm:px-6">{children}</div>
            {/* <footer className="fixed bottom-0 z-50 bg-green-600 text-white px-2 py-1 w-full">
              <Link to={"/termini-e-condizioni"} className="underline">
                Termini e Condizioni
              </Link>{" "}
            </footer> */}
          </main>
        </div>
      </div>
    </>
  );
}
