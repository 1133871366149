import React, { useEffect, useState } from 'react'
import PopupDialog from './custom/PopupDialog'
import { InputField } from '../components_used/InputField'
import ListRecharges from './ListRecharges'
import { CashIcon } from '@heroicons/react/outline'
import { LoadingIcon } from './custom/LoadingIcon'
import { useCreateRechargeBalanceMutation } from '../services/rechargeBalanceApi'
import SimpleNotification from './SimpleNotification'

function PopupRecharges({ open, setOpen, userId }) {

const [ createRechargeBalance, { isLoading, isSuccess, isError, error } ] = useCreateRechargeBalanceMutation()
const [ amount, setAmount ] = useState("")
const [ mutationResponse, setMutationResponse ] = useState({
    message: "",
    show: false,
    success: false        
})

useEffect(() => {
    if(isSuccess){
        setMutationResponse({
            message: "Ricarica Effettuata Correttamente.",
            show: true,
            success: true
        })
    }
    if(isError){
        setMutationResponse({
            message: error?.data?.message ?? "Errore generico",
            show: true,
            success:false
        })      
    }
    setAmount("")
  }, [isLoading])

  return (
    <PopupDialog open={open} setOpen={setOpen} >
         <SimpleNotification 
            message={mutationResponse.message} 
            success={mutationResponse.success}
            show={mutationResponse.show} 
            setShow={() => setMutationResponse({ ...mutationResponse, show: !mutationResponse.show })} 
        />
        <h4 className='mb-1'>Ricariche</h4>
        <InputField placeholder={"Importo Ricarica (€)"}  type={'number'} value={amount} onChange={(e) => setAmount(e.target.value)}/>
        <button
            disabled={isLoading}
            onClick={() => amount > 0 ? createRechargeBalance({ registeredUserId: userId, amount }) : null}
            type="button"
            className="w-full bg-gradient-to-r mt-1 from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-1.5 text-center inline-flex justify-center items-center gap-x-1.5 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
            >
            Ricarica
            {isLoading ? (
                <LoadingIcon size={"h-4 w-4"} color={"text-white"} />
            ) : (
                <CashIcon className="h-4 w-4" />
            )}
        </button>
        <ListRecharges userId={userId}/>
    </PopupDialog>
  )
}

export default PopupRecharges