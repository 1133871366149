import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import logo from "../images/logo_trasp.png";
import { ErrorBanner } from "../components/custom/ErrorBanner";
import {
  useChangePasswordMutation,
  useValidateTokenQuery,
  useVerifyEmailMutation,
} from "../services/recoveryPasswordApi";
import { getPublicClientIp } from "../functions/ip_address";
import SimpleNotification from "../components/SimpleNotification";
import { validatePassword } from "../functions/validate_password";

function RecoveryPasswordPage() {
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [messageNotification, setMessageNotification] = useState("");
  const [successNotification, setSuccessNotification] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { token } = useParams();
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();

  const {
    isLoading: validatingToken,
    isSuccess: validatedToken,
    isError: errorValidatingTokenEvent,
    error: errorValidatingTokenText,
    isUninitialized,
  } = useValidateTokenQuery(token, { skip: token == null });

  const [verifyEmail, { data, isLoading, isSuccess, isError, error }] =
    useVerifyEmailMutation();

  const [
    changePassword,
    {
      isLoading: changingPassword,
      isSuccess: changedPassword,
      isError: errorChangingPasswordEvent,
      error: errorChangingPasswordText,
    },
  ] = useChangePasswordMutation();

  const onClickVerifyEmail = async (e) => {
    e.preventDefault();
    const publicIpAddress = await getPublicClientIp();
    verifyEmail({ email, ipAddress: publicIpAddress }).then((res) => {
      if (res.error) {
        setErrorMessage(res.error.data.message);
        setShowErrorMessage(true);
      }
      setSuccessNotification(true);
      setMessageNotification(res.data.message);
      setShowNotification(true);
    });
  };

  const onClickChangePassword = async (e) => {
    e.preventDefault();
    if (password != confirmPassword) {
      setErrorMessage("Le password non coincidono");
      setShowErrorMessage(true);
      return;
    }
    if ((passwordError ?? "") != "") {
      return;
    }
    changePassword({ password, token }).then((res) => {
      if (res.error) {
        setErrorMessage(res.error.data.message);
        setShowErrorMessage(true);
        return;
      }
      setSuccessNotification(true);
      setMessageNotification(res.data.message);
      setShowNotification(true);
      setTimeout(() => {
        navigate("/login", { replace: true });
      }, 1000);
    });
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    if (!validatePassword(value)) {
      setPasswordError(
        "La password deve avere almeno 8 caratteri, includere un carattere speciale e una lettera maiuscola."
      );
    } else {
      setPasswordError("");
    }
    setPassword(value);
  };

  return (
    <section className="h-screen">
      <SimpleNotification
        show={showNotification}
        setShow={setShowNotification}
        message={messageNotification}
        success={successNotification}
      />
      <div className="flex min-h-full flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          {isUninitialized ? (
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <img className="w-60" src={logo} alt="Your Company" />
                <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                  Cambia la tua password
                </h2>
                {/* <p className="mt-2  leading-6 text-gray-500 text-xl">
                Accedi per continuare.
              </p> */}
              </div>

              <div className="mt-10">
                <div>
                  <form
                    onSubmit={(e) => onClickVerifyEmail(e)}
                    className="space-y-6"
                  >
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Email
                      </label>
                      <div className="mt-2">
                        <input
                          onChange={(e) => setEmail(e.target.value)}
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>{" "}
                      <span className="text-sm text-gray-500">
                        Se non si dovesse ricevere la mail, si prega di
                        controllare nella{" "}
                        <span className="underline">posta indesiderata</span>
                      </span>
                    </div>

                    <div>
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-green-facile px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-facile focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-facile"
                      >
                        Inviami il link per modificare la password
                      </button>
                      <span className="mt-3 leading-6 text-gray-500 text-sm">
                        Non hai un account?{" "}
                        <Link
                          to={"/register"}
                          className={
                            "text-green-facile hover:text-green-facile"
                          }
                        >
                          Registrati
                        </Link>
                      </span>
                    </div>
                  </form>
                </div>

                <div className="mt-10">
                  <div className="relative"></div>

                  <div className="mt-6 grid grid-cols-2 gap-4"></div>
                </div>
              </div>
            </div>
          ) : validatedToken ? (
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <img className="w-60" src={logo} alt="Your Company" />
                <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                  Inserisci la nuova password
                </h2>
                {/* <p className="mt-2  leading-6 text-gray-500 text-xl">
              Accedi per continuare.
            </p> */}
              </div>

              <div className="mt-10">
                <div>
                  <form
                    onSubmit={(e) => onClickChangePassword(e)}
                    className="space-y-6"
                  >
                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Password
                      </label>
                      <div className="mt-2">
                        <input
                          onChange={handlePasswordChange}
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="password"
                          required
                          className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>{" "}
                      {passwordError !== "" && (
                        <span className="text-red-400">{passwordError}</span>
                      )}
                    </div>
                    <div>
                      <label
                        htmlFor="confirm-password"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Conferma Password
                      </label>
                      <div className="mt-2">
                        <input
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          id="confirm-password"
                          name="confirm-password"
                          type="password"
                          autoComplete="confirm-password"
                          required
                          className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>{" "}
                    </div>

                    <div>
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-green-facile px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-facile focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-facile"
                      >
                        Modifica Password
                      </button>
                    </div>
                  </form>
                </div>

                <div className="mt-10">
                  <div className="relative"></div>

                  <div className="mt-6 grid grid-cols-2 gap-4"></div>
                </div>
              </div>
            </div>
          ) : errorValidatingTokenEvent ? (
            <div>
              Token scaduto o non valido
              <br />
              <Link
                to="/recovery-password"
                className="underline text-green-facile font-bold"
              >
                Recupera un nuovo token
              </Link>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
            alt=""
          />
        </div>
      </div>
      <ErrorBanner
        message={errorMessage}
        show={showErrorMessage}
        setShow={setShowErrorMessage}
      />
    </section>
  );
}

export default RecoveryPasswordPage;
