import React, { useEffect } from 'react'
import { useGetAllShipmentStatusesQuery, useUpdateShipmentStatusMutation } from '../services/shipmentStatusApi';
import ListTable from '../components/custom/ListTable';
import HeaderTable from '../components/custom/HeaderTable';
import HeaderColTable from '../components/custom/HeaderColTable';
import BodyTable from '../components/custom/BodyTable';
import RowTable from '../components/custom/RowTable';
import CellRowTable from '../components/custom/CellRowTable';
import HiddenHeaderColTable from '../components/custom/HiddenHeaderColTable';
import { useState } from 'react';
import { TableLoading } from '../components/shipment/LoadingTable';
import DropDownSpedisciOnlineStatus from '../components/shipment/DropDownSpedisciOnlineStatus';
import { CheckCircleIcon, CheckIcon, SearchIcon } from '@heroicons/react/solid';
import ButtonBasic from '../components/ButtonBasic';
import SearchBar from '../components/catalog/SearchBar';
import PopupDialog from '../components/custom/PopupDialog';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { debounce, Tooltip } from '@mui/material';
import DropDownShipmentStatus from '../components/DropDownShipmentStatus';
import { PopupLoading } from '../components/PopupLoading';


function ShipmentStatusPage() {
    const [openPopup, setOpenPopup] = useState(false);
    const [shipmentStatusState, setShipmentStatusState] = useState([]);
    const [filter, setFilter] = useState({ status: null, spedisciOnlineStatus: null });
    const { data: shipmentStatuses, error, isLoading, isSuccess, fulfilledTimeStamp } = useGetAllShipmentStatusesQuery(filter, {
        selectFromResult: ({ data, ...rest }) => ({
            ...rest,
            data: data ? data.map(status => ({ ...status, isSelected: false })) : [],
        }),
        refetchOnMountOrArgChange: true,
    });

    const [updateShipmentStatus, { isLoading: updatingShipmentStatus }] = useUpdateShipmentStatusMutation();

    useEffect(() => {
        if (shipmentStatuses) {
            setShipmentStatusState(shipmentStatuses);
        }
    }, [fulfilledTimeStamp]);

    const setSelectedShipmentStatus = (statuses, isChecked = null) => {
        setShipmentStatusState(prevState => {
            return prevState.map(prevStatus => {
                if(isChecked !== null) return { ...prevStatus, isSelected: isChecked };
                if (statuses.filter((status) => status.id === prevStatus.id).length > 0) {
                    return { ...prevStatus, isSelected: !prevStatus.isSelected };
                }
                return prevStatus;
            });
        });
    }

    const handleUpdateShipmentStatus = async (statuses, status) => {
        const selectedIds = statuses
            .map(status => status.id);
        await updateShipmentStatus({ ids: selectedIds, spedisciOnlineStatus: status });
    }

    if (error) return <div>Error: {error.data.message}</div>;

    const handleSearch = debounce((e) => {
        setFilter({ ...filter, status: e.target.value });
    }, 300);

    return (
        <main className='p-2'>
            {/* {shipmentStatusState.filter((status) => status.isSelected).length} */}
            <div className='mb-2 flex gap-4' >
                    <DropDownSpedisciOnlineStatus defaultValue={{ id: null, name: "Tutti" }} onSelectedStatus={(status) => {
                            let statusFilter = status.id === null ? null : status.name;
                            setFilter({ ...filter, spedisciOnlineStatus: statusFilter })
                        }}/>
                <div id='search-bar' className='flex items-end gap-2 flex-grow'>
                    <input type='text' className='border-solid border px-1 py-1.5 rounded-md shadow-sm w-full' onChange={handleSearch} placeholder={"Cerca Stato Spedisci Online"}/>
                </div>
            </div>
            <div className='mb-2 flex items-center justify-end gap-2'>
                <Tooltip title='Seleziona gli stati da modificare cliccando sulle righe e premi il bottone' arrow>
                    <InformationCircleIcon className='h-6 w-6 text-gray-500' />
                </Tooltip>
                <ButtonBasic textButton={"Modifica Stato"} 
                    disabled={shipmentStatusState.filter((status) => status.isSelected).length == 0}
                    onClick={() => setOpenPopup(true)} />
            </div>
            <PopupSetSpedisciOnlineStatus
                onSetStatus={(statuses, status) => handleUpdateShipmentStatus(shipmentStatusState.filter((status) => status.isSelected), status)}
                open={openPopup} setOpen={setOpenPopup} statuses={shipmentStatusState.filter((status) => status.isSelected)}
            />
            <PopupLoading open={updatingShipmentStatus} setOpen={() => {}}></PopupLoading>
            {isLoading ? <TableLoading /> : 
                <ListTable>
                    <HeaderTable>
                        <RowTable>
                            <HeaderColTable><input type='checkbox' onChange={(e) => setSelectedShipmentStatus(shipmentStatusState, e.target.checked)} /></HeaderColTable>
                            <HeaderColTable>Stato Spedisci Online</HeaderColTable>
                            <HeaderColTable>Stato Piattaforma</HeaderColTable>
                        </RowTable>
                    </HeaderTable>
                    <BodyTable>
                        {shipmentStatusState.map((status) => (
                            <RowTable key={status.id} >
                                <CellRowTable>{status.isSelected ? <CheckCircleIcon className='h-4 w-4' /> : <></>}</CellRowTable>
                                <CellRowTable onClick={() => setSelectedShipmentStatus([status])} >{status.status}</CellRowTable>
                                <CellRowTable>
                                     <DropDownShipmentStatus defaultValue={status.spedisciOnlineStatus} onChange={(value) => handleUpdateShipmentStatus([status], value.label) }/>
                                </CellRowTable>
                            </RowTable>
                        ))}
                    </BodyTable>
                </ListTable>
            }
        </main>
    );

}

export const PopupSetSpedisciOnlineStatus = ({ open, setOpen, onSetStatus, statuses }) => {
    const [selectedStatus, setSelectedStatus] = useState(""); 

    useEffect(() => {
        if (!open) {
            setSelectedStatus("");
        }
    }, [open]);

    return (
        <PopupDialog open={open} setOpen={setOpen}>
            <div className="p-4">
                <div className="flex items-center gap-2 mb-2">
                    <h2 className="text-lg font-medium">Imposta Stato</h2>
                    <DropDownSpedisciOnlineStatus label={""} onSelectedStatus={(status) => {
                        let newStatus = status.id === null ? "" : status.name;
                        setSelectedStatus(newStatus);
                    }} defaultValue={{ id: null, name: "Tutti" }}/>
                </div>
                <ListTable>
                    <HeaderTable>
                        <RowTable>
                            <HeaderColTable>Stato Spedisci Online</HeaderColTable>
                            <HeaderColTable>Stato Piattaforma</HeaderColTable>
                        </RowTable>
                    </HeaderTable>
                    <BodyTable>
                        {statuses.map((status) => (
                            <RowTable key={status.id} >
                                <CellRowTable>{status.status}</CellRowTable>
                                <CellRowTable>{selectedStatus === "" ? status.spedisciOnlineStatus : <span><span className='line-through'>{status.spedisciOnlineStatus}</span> <span className='font-bold'>{selectedStatus}</span></span>}</CellRowTable>
                            </RowTable>
                        ))}
                    </BodyTable>
                </ListTable>

                {selectedStatus === "" ?  <></> : <div className="mt-4 flex justify-end gap-2">
                    <ButtonBasic textButton="Conferma" disabled={selectedStatus === ""} onClick={() => {
                        onSetStatus(statuses, selectedStatus);
                        setOpen(false);
                    }} />
                </div>}
            </div>
        </PopupDialog>
    );
    
}


export default ShipmentStatusPage