import React from "react";
import CustomerProductBox from "./CustomerProductBox";
import { useSelector } from "react-redux";
import { CardCustomerProduct } from "./CardCustomerProduct";

function CustomerProductList({ customerProducts, expandAll }) {
  const auth = useSelector((state) => state.auth);
  return (
    <main className="grid pt-4 grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:gap-x-8 xl:grid-cols-4 lg:grid-cols-3 items-start">
      {auth.roles.find((r) => r == 1) ?
      customerProducts?.map((p, index) => (
        <CardCustomerProduct fullName={p.fullname} products={p.products} defaultOpen={expandAll}/>
      )) :
       customerProducts?.map((p, index) => (
        <CustomerProductBox product={p} key={index} />
      ))}
    </main>
  );
}

export default CustomerProductList;
