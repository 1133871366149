import { userApi } from "./userApi";

const maintenanceApi = userApi.injectEndpoints({
    endpoints: (builder) => ({
        getMaintenanceMode: builder.query({
            query: () => `maintenance`,
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetMaintenanceModeQuery,
} = maintenanceApi;