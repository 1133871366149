import { userApi } from "./userApi";

const shipmentOnHoldApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllShipmentsOnHold: builder.query({
        query: ({ filter, page, rowsPage }) => {
          const queryParams = new URLSearchParams();
          if (filter.searchText) queryParams.append('searchText', filter.searchText);
          if (filter.isCashOnDelivery) queryParams.append('isCashOnDelivery', filter.isCashOnDelivery);
          if (filter.isPaidOrder) queryParams.append('isPaidOrder', filter.isPaidOrder);
          if (filter.isPrinted) queryParams.append('isPrinted', filter.isPrinted);
          if (filter.dateRange) {
            queryParams.append('startDate', filter.dateRange.startDate);
            queryParams.append('endDate', filter.dateRange.endDate);
          }
          if (filter.shopifyStoreId) queryParams.append('shopifyStoreId', filter.shopifyStoreId);
          if (filter.codfacileCustomer) queryParams.append('codfacileCustomer', filter.codfacileCustomer.id);
          if (filter.shipmentStatus) queryParams.append('shipmentStatus', filter.shipmentStatus.id);
          if (filter.handlingStatus) queryParams.append('handlingStatus', filter.handlingStatus.id);
  
          queryParams.append('page', page);
          queryParams.append('rowsPage', rowsPage);
  
          return {
            url: `shipment-on-hold?${queryParams.toString()}`,
          };
        },
        providesTags: ["ShipmentsOnHold"],
      }),
    getHoldByShipmentId: builder.query({
      query: (shipmentId) => `shipment-on-hold/${shipmentId}`,
      providesTags: ["ShipmentsOnHold"],
    }),
    insertShipmentOnHold: builder.mutation({
      query: (newShipmentOnHold) => ({
        url: `shipment-on-hold`,
        method: "POST",
        body: newShipmentOnHold,
      }),
      invalidatesTags: ["ShipmentsOnHold"],
    }),
    updateShipmentOnHold: builder.mutation({
      query: ({ id, ...updatedShipmentOnHold }) => ({
        url: `shipment-on-hold/${id}`,
        method: "PUT",
        body: updatedShipmentOnHold,
      }),
      invalidatesTags: ["ShipmentsOnHold"],
    }),
    deleteShipmentOnHold: builder.mutation({
      query: (id) => ({
        url: `shipment-on-hold/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ShipmentsOnHold"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllShipmentsOnHoldQuery,
  useGetHoldByShipmentIdQuery,
  useInsertShipmentOnHoldMutation,
  useUpdateShipmentOnHoldMutation,
  useDeleteShipmentOnHoldMutation,
} = shipmentOnHoldApi;