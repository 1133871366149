import React, { useEffect, useState } from "react";
import {
  useDeletePromotionMutation,
  useGetAllPromotionQuery,
  useInsertPromotionMutation,
  useUpdatePromotionMutation,
} from "../services/promotionApi";
import { LoadingIcon } from "../components/custom/LoadingIcon";
import ListCardWithLinks from "../components/custom/InCardWithLinks/ListCardWithLinks";
import RowCardWithLinks from "../components/custom/InCardWithLinks/RowCardWithLinks";
import {
  PromotionDuration,
  PromotionDurationDropDown,
} from "../enums/PromotionEnums";
import { InputField } from "../components_used/InputField";
import {
  PencilAltIcon,
  SaveIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import ButtonTable from "../components/custom/ButtonTable";
import ToggleButton from "../components/custom/ToggleButton";
import DropDown from "../components/order/NewShopifyOrderList/DropDown";
import moment from "moment/moment";
import SimpleNotification from "../components/SimpleNotification";
import PopupConfirmation from "../components/PopupConfirmation";
import { DropDownCustomer } from "../components/customer/DropDownCustomer";
import TextCopyToClipboard from "../components/catalog/TextCopyToClipboard";

const durationInMonthsDropDown = [
  { id: 0, name: "Seleziona.." },
  { id: 1, name: "1" },
  { id: 2, name: "2" },
  { id: 3, name: "3" },
  { id: 4, name: "4" },
  { id: 5, name: "5" },
  { id: 6, name: "6" },
  { id: 7, name: "7" },
  { id: 8, name: "8" },
  { id: 9, name: "9" },
  { id: 10, name: "10" },
  { id: 11, name: "11" },
  { id: 12, name: "12" },
];

function getDescrizioneDuration(duration, durationInMonths) {
  duration = duration ?? "once";
  return duration == PromotionDuration.Once
    ? "applicato al primo abbonamento"
    : duration == PromotionDuration.Forever
    ? "applicato ad ogni rinnovo abbonamento"
    : duration == PromotionDuration.Repeating
    ? `applicato ad ogni rinnovo per massimo ${durationInMonths ?? 1} mesi`
    : "";
}

function PromotionCodePage() {
  const [showNotification, setShowNotification] = useState(false);
  const [messageNotification, setMessageNotification] = useState("");
  const [isSuccessNotification, setIsSuccessNotification] = useState(true);
  const [filterCustomer, setFilterCustomer] = useState({});

  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const [messageConfirmation, setMessageConfirmation] = useState("");

  const [submitError, setSubmitError] = useState("");

  const [promotionCode, setPromotionCode] = useState({});
  const [promotionCodeToDelete, setPromotionCodeToDelete] = useState({});

  const [expired, setExpired] = useState(false);
  const [filterExpiresDate, setFilterExpiresDate] = useState(
    moment().format("yyyy-MM-DD 23:59:59")
  );

  const [
    deletePromotion,
    {
      isLoading: loadingDeletePromotion,
      isSuccess: successDeletePromotion,
      isError: errorDeletePromotionEvent,
      error: errorDeletePromotionText,
    },
  ] = useDeletePromotionMutation();

  const [
    insertPromotion,
    {
      isLoading: loadingInsertPromotion,
      isSuccess: successInsertPromotion,
      isError: errorInsertPromotionEvent,
      error: errorInsertPromotionText,
    },
  ] = useInsertPromotionMutation();
  const [
    updatePromotion,
    {
      isLoading: loadingUpdatePromotion,
      isSuccess: successUpdatePromotion,
      isError: errorUpdatePromotionEvent,
      error: errorUpdatePromotionText,
    },
  ] = useUpdatePromotionMutation();

  const onSubmit = async (e) => {
    e.preventDefault();
    if (
      (promotionCode?.amountOff?.toString() ?? "0").includes(".") ||
      (promotionCode?.amountOff?.toString() ?? "0").includes(",")
    ) {
      setSubmitError(
        "Impossibile inserire valori decimali come sconto in euro"
      );
      return;
    }
    if (
      (promotionCode?.minimumAmount?.toString() ?? "0").includes(".") ||
      (promotionCode?.minimumAmount?.toString() ?? "0").includes(",")
    ) {
      setSubmitError(
        "Impossibile inserire valori decimali come minimo sconto applicabile"
      );
      return;
    }
    const promotionObj = {
      ...promotionCode,
      customer: (filterCustomer?.id ?? 0) == 0 ? null : filterCustomer,
      expiresAt: expired
        ? moment(filterExpiresDate).format("yyyy-MM-DD 23:59:59")
        : undefined,
    };
    if ((promotionCode?.id ?? "") == 0) {
      await insertPromotion(promotionObj).then((response) => {
        if (response.error) {
          setShowNotification(true);
          setMessageNotification(response.error.data.message);
          setIsSuccessNotification(false);
          return;
        }
        setMessageNotification(undefined);
        setIsSuccessNotification(true);
        setShowNotification(true);
        setPromotionCode({});
        setFilterCustomer({});
      });
    } else {
      await updatePromotion(promotionObj).then((response) => {
        if (response.error) {
          setShowNotification(true);
          setMessageNotification(response.error.data.message);
          setIsSuccessNotification(false);
          
          return;
        }
        setMessageNotification(undefined);
        setIsSuccessNotification(true);
        setShowNotification(true);
        setPromotionCode({});
        setFilterCustomer({});
      });
    }
  };
  const handleConfirmPromotionDelete = async () => {
    await deletePromotion(promotionCodeToDelete).then((response) => {
      if (response.error) {
        setShowNotification(true);
        setMessageNotification(response.error.data.message);
        setIsSuccessNotification(false);
        return;
      }
      setShowConfirmationMessage(false);
      setMessageNotification("Coupon cancellato con successo!");
      setIsSuccessNotification(true);
      setShowNotification(true);
      setPromotionCodeToDelete({});
    });
  };

  const handleEditClick = (promo) => {
    setPromotionCode(promo);
    setFilterCustomer(promo.registeredUser);
  };

  useEffect(() => {
    if ((filterCustomer?.id ?? 0) === 0) {
      setPromotionCode({ ...promotionCode, amountFee: 0 });
    }
  }, [filterCustomer]);

  return (
    <main className="flex gap-3 mt-2 items-start flex-wrap">
      <SimpleNotification
        show={showNotification}
        setShow={setShowNotification}
        message={messageNotification}
        success={isSuccessNotification}
      />
      <PopupConfirmation
        message={messageConfirmation}
        setShow={setShowConfirmationMessage}
        show={showConfirmationMessage}
        handleConfirmClick={handleConfirmPromotionDelete}
      />
      <div className="flex-1">
        <form className="space-y-6" onSubmit={onSubmit}>
          <div className="sm:flex gap-5 items-end">
            <InputField
              disabled={promotionCode.id !== undefined}
              label={"Codice Alias"}
              value={promotionCode?.aliasCode ?? ""}
              onChange={(aliasCode) =>
                setPromotionCode({
                  ...promotionCode,
                  aliasCode: aliasCode.target.value,
                })
              }
            />
            <InputField
              disabled={
                (promotionCode?.amountOff ?? 0) > 0 ||
                promotionCode.id !== undefined
              }
              required={false}
              type={"number"}
              label={"% Sconto"}
              value={promotionCode?.percentOff ?? ""}
              onChange={(percentOff) =>
                setPromotionCode({
                  ...promotionCode,
                  percentOff: percentOff.target.value,
                })
              }
            />
            <InputField
              disabled={
                (promotionCode?.percentOff ?? 0) > 0 ||
                promotionCode.id !== undefined
              }
              required={false}
              type={"number"}
              label={"€ Sconto"}
              step={1}
              value={promotionCode?.amountOff ?? ""}
              onChange={(amountOff) =>
                setPromotionCode({
                  ...promotionCode,
                  amountOff: amountOff.target.value,
                })
              }
            />
            <InputField
              disabled={promotionCode.id !== undefined}
              type={"number"}
              step={1}
              required={false}
              label={"Importo Minimo Applicabile"}
              value={promotionCode?.minimumAmount ?? ""}
              onChange={(minimumAmount) =>
                setPromotionCode({
                  ...promotionCode,
                  minimumAmount: minimumAmount.target.value,
                })
              }
            />
          </div>

          <div className="flex justify-between gap-3 items-center">
            <div className="flex gap-3 flex-wrap items-end">
              <DropDownCustomer
                selectedValue={filterCustomer}
                onSelectedValue={(customer) => {
                  setFilterCustomer(customer.value)
                }}
                isSearchable={true}
              />
              <InputField
                disabled={(filterCustomer?.id ?? 0) == 0}
                type={"number"}
                label={"€ Commissione"}
                value={promotionCode?.amountFee ?? ""}
                onChange={(amountFee) =>
                  setPromotionCode({
                    ...promotionCode,
                    amountFee: amountFee.target.value,
                  })
                }
              />
              {promotionCode.id === undefined ? (
                <DropDown
                  label={"Durata Sconto"}
                  items={PromotionDurationDropDown}
                  selectedItem={PromotionDurationDropDown.find(
                    (p) => p.name == (promotionCode?.duration ?? "Seleziona..")
                  )}
                  onChange={(promoDuration) =>
                    setPromotionCode({
                      ...promotionCode,
                      duration: promoDuration.name,
                    })
                  }
                />
              ) : (
                <></>
              )}

              {promotionCode?.duration == PromotionDuration.Repeating && (
                <DropDown
                  label={"Durata In Mesi"}
                  items={durationInMonthsDropDown}
                  selectedItem={durationInMonthsDropDown.find(
                    (d) => d.id == (promotionCode?.durationInMonths ?? 0)
                  )}
                  onChange={(durationInMonths) =>
                    setPromotionCode({
                      ...promotionCode,
                      durationInMonths: durationInMonths?.id.toString() ?? 0,
                    })
                  }
                />
              )}
              {promotionCode.id === undefined ? (
                <div>
                  <label className=" text-sm font-medium leading-6 text-gray-900 flex items-center gap-2">
                    Data Scadenza{" "}
                    <input
                      type={"checkbox"}
                      onChange={(e) => setExpired(e.target.checked)}
                      value={expired}
                    />
                  </label>
                  <div className="flex items-center gap-2">
                    <input
                      disabled={!expired}
                      className="shadow-sm ring-1 ring-inset ring-gray-300 rounded-md bg-white py-1.5 px-3"
                      type="date"
                      value={filterExpiresDate}
                      onChange={(e) => setFilterExpiresDate(e.target.value)}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <ToggleButton
              enabled={promotionCode?.active ?? false}
              text={"Attivo?"}
              onChangeEvent={(actived) =>
                setPromotionCode({
                  ...promotionCode,
                  active: actived,
                })
              }
            />
          </div>
          <div className="flex flex-col">
            {`lo sconto ${
              promotionCode?.active ?? false ? "attivo" : "non attivo"
            } '${promotionCode?.aliasCode ?? ""}' ${
              (promotionCode?.percentOff ?? 0) == 0
                ? `di ${promotionCode?.amountOff ?? 0}€`
                : `del ${promotionCode?.percentOff ?? 0}%`
            } viene ${getDescrizioneDuration(
              promotionCode.duration,
              promotionCode.durationInMonths
            )}`}
            <button
              disabled={false}
              type="submit"
              className="w-full justify-center bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 inline-flex items-center gap-x-1.5 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
            >
              {promotionCode.id == null ? (
                <>
                  Salva
                  <SaveIcon className="h-4 w-4" />
                </>
              ) : (
                <>
                  Modifica
                  <PencilAltIcon className="h-4 w-4" />
                </>
              )}
            </button>
            {promotionCode?.id == null ? (
              <></>
            ) : (
              <button
                disabled={false}
                onClick={() => {
                  setPromotionCode({});
                  setFilterCustomer({});
                }}
                type="submit"
                className="w-full justify-center bg-gradient-to-r focus:ring-4 bg-gray-300 focus:outline-none focus:ring-gray-300 dark:focus:ring-gray-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 inline-flex items-center gap-x-1.5 text-gray-900 shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
              >
                Annulla
              </button>
            )}
          </div>
          {submitError && <span className="text-red-800">{submitError}</span>}
        </form>
      </div>
      <div className="flex-1">
        <PromotionList
          handleEditClick={(promo) => handleEditClick(promo)}
          handleDeleteRow={(promoSelected) => {
            setPromotionCodeToDelete(promoSelected);
            setMessageConfirmation(
              `Confermi l'eliminazione del coupon '${
                promoSelected?.aliasCode ?? ""
              }'`
            );
            setShowConfirmationMessage(true);
          }}
          setPromotionCode={(promoSelected) => setPromotionCode(promoSelected)}
        />
      </div>
    </main>
  );
}

function PromotionList({ handleDeleteRow, handleEditClick }) {
  const {
    data: promotions,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllPromotionQuery();
  return isLoading ? (
    <LoadingIcon />
  ) : isSuccess ? (
    <ListCardWithLinks>
      {promotions?.map((promo, index) => (
        <RowCardWithLinks
          key={index}
          actionButtons={
            <div className="z-10 flex gap-2">
              <ButtonTable
                Icon={PencilAltIcon}
                color={
                  "from-green-400 via-green-500 to-green-600 focus:ring-green-300 dark:focus:ring-green-800"
                }
                onClick={() => {
                  handleEditClick(promo);
                }}
              />
              <ButtonTable
                Icon={TrashIcon}
                color={
                  "from-red-400 via-red-500 to-red-600 focus:ring-red-300 dark:focus:ring-red-800"
                }
                onClick={() => handleDeleteRow(promo)}
              />
            </div>
          }
          title={
            <>
              <span>{`${promo.aliasCode}${
                promo.registeredUser == null
                  ? ""
                  : ` per ${promo.registeredUser.name} ${promo.registeredUser.surname}`
              }`}</span>{" "}
              <br />{" "}
              <div className="z-10">
                <TextCopyToClipboard
                  text={`https://app.codfacile.com/register/${promo.aliasCode}`}
                  textHover={"COPIA REFERRAL"}
                />
              </div>
            </>
          }
          subTitle={`sconto ${
            (promo?.percentOff ?? 0) == 0
              ? `di ${promo?.amountOff ?? 0}€`
              : `del ${promo?.percentOff ?? 0}%`
          } ${getDescrizioneDuration(promo.duration, promo.durationInMonths)}`}
          trailingTitle={
            <p>
              {promo.expiresAt
                ? `scade il ${promo.expiresAt}`
                : "nessuna scadenza"}
            </p>
          }
          trailingSubTitle={
            <div className="mt-1 flex items-center gap-x-1.5">
              <div
                className={`flex-none rounded-full ${
                  promo.active ? "bg-emerald-500/20" : "bg-red-500/20"
                }  p-1`}
              >
                <div
                  className={`h-1.5 w-1.5 rounded-full ${
                    promo.active ? "bg-emerald-500" : "bg-red-500"
                  } `}
                />
              </div>
              <p className="text-xs leading-5 text-gray-500">
                {promo.active ? "ATTIVO" : "NON ATTIVO"}
              </p>
            </div>
          }
        />
      ))}
    </ListCardWithLinks>
  ) : (
    <></>
  );
}

export default PromotionCodePage;
