import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, Autocomplete } from "@react-google-maps/api";

const libraries = [process.env.REACT_APP_GOOGLE_PLACES_API]

const GoogleInputAddress = ({ onHandlePlaceChanged, address, setAddress }) => {
  const [autocomplete, setAutocomplete] = useState(null);

  const handlePlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      onHandlePlaceChanged(place)
    }
  };

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} libraries={libraries}>
      <div className="flex-1 sm:m-0 mb-2">
        <label htmlFor="billingAddress" className="block text-sm font-medium leading-6 text-gray-900">Indirizzo</label>
        <Autocomplete
          onLoad={(auto) => setAutocomplete(auto)}
          onPlaceChanged={handlePlaceChanged}
        >
          <input
            id="billingAddress"
            type="text"
            value={address}
            className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-facile sm:text-sm sm:leading-6"
            onChange={setAddress}
          />
        </Autocomplete>
      </div>
    </LoadScript>
  );
};

export default GoogleInputAddress;
