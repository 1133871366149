import React, { useEffect, useState } from 'react'
import { useAddCodShippingCostMutation, useDeleteCodShippingCostMutation, useGetAllCodShippingCostQuery, useUpdateCodShippingCostMutation } from '../../services/codShippingCostApi'
import useHandleMutationResponse from '../../hooks/useHandleMutationResponse'
import { TableLoading } from '../shipment/LoadingTable'
import ListTable from '../custom/ListTable'
import HeaderTable from '../custom/HeaderTable'
import RowTable from '../custom/RowTable'
import HeaderColTable from '../custom/HeaderColTable'
import HiddenHeaderColTable from '../custom/HiddenHeaderColTable'
import BodyTable from '../custom/BodyTable'
import CellRowTable from '../custom/CellRowTable'
import ButtonTable from '../custom/ButtonTable'
import { CheckIcon, PencilIcon, PlusIcon, TrashIcon, XIcon } from '@heroicons/react/outline'
import SimpleNotification from '../SimpleNotification'
import ModalConfirmationRequest from '../custom/ModalConfirmationRequest'


function CodShippingCostList() {

    const [ mutationResponse, setMutationResponse ] = useState({
        message: "",
        show: false,
        success: false        
    })
    const [showConfirmDialog, setShowConfirmDialog ] = useState({
      handleConfirm: null,
      message: "",
      showModal: false
    })
    const { data:codShippingCosts, isLoading: loadingCodShippingCosts, isSuccess: loadedCodShippingCosts, isError: isErrorLoadingCodShippingCosts, error: errorLoadingCodShippingCosts } = useGetAllCodShippingCostQuery()
    const [ addCodShippingCost, { isLoading: addingCodShippingCost, isSuccess: addedCodShippingCost, isError: isErrorAddingCodShippingCost , error: errorAddingCodShippingCost }] = useAddCodShippingCostMutation()
    const [ updateCodShippingCost, { isLoading: updatingCodShippingCost, isSuccess: updatedCodShippingCost, isError: isErrorUpdatingCodShippingCost, error: errorUpdatingCodShippingCost }] = useUpdateCodShippingCostMutation()
    const [ deleteCodShippingCost, { isLoading: deletingCodShippingCost, isSuccess: deletedCodShippingCost, isError: isErrorDeletingCodShippingCost, error: errorDeletingCodShippingCost }] = useDeleteCodShippingCostMutation()

    useHandleMutationResponse({
        successEvent: addedCodShippingCost,
        errorEvent: isErrorAddingCodShippingCost,
        successMessage: "Costo Contrassegno Aggiunto Correttamente",
        errorMessage: errorAddingCodShippingCost,
        setMutationResponse, // Passa il setter
        dependencies: [addingCodShippingCost], // Dipendenza specifica
      });
    useHandleMutationResponse({
        successEvent: updatedCodShippingCost,
        errorEvent: isErrorUpdatingCodShippingCost,
        successMessage: "Costo Contrassegno Modificato Correttamente",
        errorMessage: errorUpdatingCodShippingCost,
        setMutationResponse, // Passa il setter
        dependencies: [updatingCodShippingCost], // Dipendenza specifica
      });
    useHandleMutationResponse({
        successEvent: deletedCodShippingCost,
        errorEvent: isErrorDeletingCodShippingCost,
        successMessage: "Costo Contrassegno Eliminato Correttamente",
        errorMessage: errorDeletingCodShippingCost,
        setMutationResponse, // Passa il setter
        dependencies: [deletingCodShippingCost], // Dipendenza specifica
      });

      
  return (
    <main>
      <ModalConfirmationRequest message={showConfirmDialog.message} handleConfirm={showConfirmDialog.handleConfirm} showModal={showConfirmDialog.showModal} setShowModal={(show) => setShowConfirmDialog({...showConfirmDialog, showModal: show})}/>
        <SimpleNotification message={mutationResponse.message} show={mutationResponse.show} setShow={() => setMutationResponse({...mutationResponse, show: false})} success={mutationResponse.success} />
        {loadingCodShippingCosts ? (
        <TableLoading />
      ) : loadedCodShippingCosts ? (
        <ListTable>
          <HeaderTable>
            <RowTable>
              <HeaderColTable className={"text-center"}>Valore Massimo</HeaderColTable>
              <HeaderColTable className={"text-center"}>Prezzo Fisso</HeaderColTable>
              <HiddenHeaderColTable />
            </RowTable>
          </HeaderTable>
          <BodyTable>
            {codShippingCosts.map((cost) => (
              <RowCodShippingCostList key={cost.id} codShippingCost={cost} onEditClick={(codShippingCost) => updateCodShippingCost({ codShippingCost })} onDeleteClick={(codShippingCost) => {
                setShowConfirmDialog({
                  handleConfirm: () => {
                    deleteCodShippingCost(codShippingCost.id).finally(() => setShowConfirmDialog({ ...showConfirmDialog, showModal: false }))
                  },
                  message: `Vuoi cancellare il prezzo fisso di '${codShippingCost.fixedCost}' con valore massimo '${codShippingCost.maxAmount}' ?`,
                  showModal: true
                })
              }}/>
            ))}
          </BodyTable>
        </ListTable>
      ) : isErrorLoadingCodShippingCosts ? (
        <p>{errorLoadingCodShippingCosts}</p>
      ) : (
        <></>
      )}
      <button
        disabled={false}
        onClick={() => {
          addCodShippingCost({ fixedCost: 0, maxAmount: 0 })
        }}
        type="submit"
        className="mt-2 w-full justify-center bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 inline-flex items-center gap-x-1.5 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
      >
        <PlusIcon className="h-4 w-4" />
        Aggiungi Costo Contrassegno
      </button>
    </main>
  )
}

export default CodShippingCostList


function RowCodShippingCostList({ codShippingCost, onEditClick, onDeleteClick }) {
  const [editMode, setEditMode] = useState({
    isActive: false,
    valueToEdit: undefined
  })
  return (
    <RowTable key={codShippingCost.id}>
      <CellRowTable textAlign={"text-center"} >{editMode.isActive ? <input className='border-solid border border-gray-200 rounded-md pl-1' value={editMode?.valueToEdit?.maxAmount} onChange={(e) => setEditMode({ ...editMode, valueToEdit: { ...editMode.valueToEdit, maxAmount: e.target.value }})} /> : codShippingCost.maxAmount}</CellRowTable>
      <CellRowTable textAlign={"text-center"} >{editMode.isActive ? <input className='border-solid border border-gray-200 rounded-md pl-1' value={editMode?.valueToEdit?.fixedCost} onChange={(e) => setEditMode({ ...editMode, valueToEdit: { ...editMode.valueToEdit, fixedCost: e.target.value }})} /> : codShippingCost.fixedCost}</CellRowTable>
      <CellRowTable>
        <div className="flex gap-2">
          <ButtonTable
            Icon={editMode.isActive ? CheckIcon : PencilIcon}
            color={
              "from-green-400 via-green-500 to-green-600 focus:ring-green-300 dark:focus:ring-green-800"
            }
            onClick={() => {
              if(!editMode.isActive){
                setEditMode({ isActive: true, valueToEdit: codShippingCost })
                return
              }
              onEditClick(editMode?.valueToEdit)
              setEditMode({ isActive: false, valueToEdit: null })
            }}
          />
          <ButtonTable
            Icon={editMode.isActive ? XIcon : TrashIcon}
            color={
              "from-red-400 via-red-500 to-red-600 focus:ring-red-300 dark:focus:ring-red-800"
            }
            onClick={() => {
              if(editMode.isActive){
                setEditMode({ isActive: false, valueToEdit: null })
                return;
              }
              onDeleteClick(codShippingCost)
            }}
          />
        </div>
      </CellRowTable>
  </RowTable>
  )
}

