export const DatePickerBasic = ({ className, onChange, value, label, disabled, min }) => {
    return (
        <div className={className}>
            {label && <label>{label}</label>}
            <input
                className="shadow-sm ring-1 ring-inset ring-gray-300 rounded-md bg-white py-1.5 px-3 w-full"
                type="date"
                disabled={disabled}
                value={value}
                onChange={onChange}
                min={min}
            />
        </div>
    )
}