import { userApi } from "./userApi";

const logisticsStockPriceListApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getLogisticsStockPriceList: builder.query({
      query: () => `logistics-stock-price-list/get`,
      providesTags: ["LogisticsStockPriceList"],
    }),
    getLogisticsStockPriceListPricesById: builder.query({
      query: (id) => `logistics-stock-price-list/prices/get-by-id/${id}`,
    }),
    getLogisticsStockPriceListPricesByUserId: builder.query({
      query: () => `logistics-stock-price-list/prices/get-by-user-id`,
    }),
    addLogisticsStockPriceList: builder.mutation({
      query: (logisticsStockPriceListNew) => ({
        url: `logistics-stock-price-list/add`,
        method: "POST",
        body: logisticsStockPriceListNew,
      }),
      invalidatesTags: [
        "LogisticsStockPriceList",
      ],
    }),
    editLogisticsStockPriceList: builder.mutation({
      query: ({ id, logisticsStockPriceListEdited }) => ({
        url: `logistics-stock-price-list/edit/${id}`,
        method: "PUT",
        body: logisticsStockPriceListEdited,
      }),
      invalidatesTags: [
        "LogisticsStockPriceList",
      ],
    }),
    deleteLogisticsStockPriceList: builder.mutation({
      query: (id) => ({
        url: `logistics-stock-price-list/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "LogisticsStockPriceList",
      ],
    }),
    addLogisticsStockPrice: builder.mutation({
      query: ({ id, prices }) => ({
        url: `logistics-stock-price-list/add-prices/${id}`,
        method: "POST",
        body: prices,
      }),
      invalidatesTags: [
        "LogisticsStockPriceList",
      ],
    }),
    editLogisticsStockPrice: builder.mutation({
      query: ({ id, price, actionType, shipmentValuePercentage }) => ({
        url: `logistics-stock-price-list/edit-price/${id}`,
        method: "PUT",
        body: { price, actionType, shipmentValuePercentage },
      }),
      invalidatesTags: [
        "LogisticsStockPriceList",
      ],
    }),
    deleteLogisticsStockPrice: builder.mutation({
      query: (id) => ({
        url: `logistics-stock-price-list/delete-price/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "LogisticsStockPriceList",
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetLogisticsStockPriceListQuery,
  useGetLogisticsStockPriceListPricesByIdQuery,
  useGetLogisticsStockPriceListPricesByUserIdQuery,
  useAddLogisticsStockPriceListMutation,
  useEditLogisticsStockPriceListMutation,
  useDeleteLogisticsStockPriceListMutation,
  useAddLogisticsStockPriceMutation,
  useEditLogisticsStockPriceMutation,
  useDeleteLogisticsStockPriceMutation,
} = logisticsStockPriceListApi;