import { Link } from "react-router-dom";
import logo from "../../../images/logo_trasp.png";
import { useState } from "react";
import { LoadingIcon } from "../../../components/custom/LoadingIcon";
import { useCheckAliasCodeQuery } from "../../../services/promotionApi";
import { validatePassword } from "../../../functions/validate_password";
import { InputField } from "../../../components_used/InputField";
import GoogleInputAddress from "./GoogleInputAddress";

export const RegistrationForm = ({
  isLoading,
  handleSignUp,
  promotionCode,
  registeredUser,
  setRegisteredUser
}) => {
  const [passwordError, setPasswordError] = useState("");
  const [addressError, setAddressError] = useState("");
  const {
    data: promo,
    isLoading: checkingAliasCode,
    isSuccess: verifiedAliasCode,
    isError: unverifiedAliasCode,
    errorCheckingText,
  } = useCheckAliasCodeQuery(promotionCode, {
    skip: promotionCode == undefined,
  });

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img className="mx-auto w-60" src={logo} alt="Your Company" />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Registra il tuo account
        </h2>
        <p className="m-0 text-center">
          Crea il tuo account per iniziare a vendere con noi.
        </p>
      </div>
      {verifiedAliasCode && (
        (promo?.promo?.id ?? 0) !== 0 && (
          <div className="pointer-events-none sm:flex sm:justify-center sm:px-6 mt-3">
            <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-green-facile px-6 py-2.5 rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
              <p className="text-sm leading-6 text-white">
                <a href="#">
                  <strong className="font-semibold">Congratulazioni</strong>
                  <svg
                    viewBox="0 0 2 2"
                    aria-hidden="true"
                    className="mx-2 inline h-0.5 w-0.5 fill-current"
                  >
                    <circle r={1} cx={1} cy={1} />
                  </svg>
                  Il tuo codice promo <b>{promo.promo.aliasCode}</b> è valido.
                  {(promo.promo.amountOff ?? 0) == 0 &&
                  (promo.promo.percentOff ?? 0) == 0
                    ? ""
                    : "Lo sconto verrà applicato automaticamente al checkout."}
                </a>
              </p>
            </div>
          </div>
        ) 
      )}
      <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-2xl">
        <form
          className="space-y-6"
          onSubmit={(e) =>
            passwordError == "" ? handleSignUp(e) : e.preventDefault()
          }
        >
          <p className="text-2xl font-bold m-0">Dati d'accesso</p>
          <div className="sm:flex gap-5">
            <InputField
              id="name"
              label="Nome"
              onChange={(e) => {
                if (/^[a-zA-Z]*$/.test(e.target.value)) {
                  setRegisteredUser({ ...registeredUser, name: e.target.value })
                }
              }}
              value={registeredUser.name}
              required={true}
            />
            <InputField
              id="surname"
              label="Cognome"
              onChange={(e) => setRegisteredUser({ ...registeredUser, surname: e.target.value })}
              value={registeredUser.surname}
              required={true}
            />
          </div>
          <InputField
            required={true}
            id="email"
            label="Email"
            type="email"
            onChange={(e) => setRegisteredUser({ ...registeredUser, email: e.target.value })}
            value={registeredUser.email}
            autoComplete="email"
          />
          <span className="text-gray-400">
            Verificare la correttezza della mail, questa verrà utilizzata per la
            gestione dell'abbonamento.
          </span>
          <div>
            <div className="sm:flex gap-5">
              <InputField
                required={true}
                id="password"
                label="Password"
                type="password"
                onChange={(e) => {
                  const { value } = e.target
                  setRegisteredUser({ ...registeredUser, password: value })
                  if (!validatePassword(value)) {
                    setPasswordError(
                      "La password deve avere almeno 8 caratteri, includere un carattere speciale e una lettera maiuscola."
                    );
                    return;
                  }
                  setPasswordError("");
                }}
                value={registeredUser.password}
                autoComplete="current-password"
              />
              <InputField
                required={true}
                id="confirm-password"
                label="Conferma Password"
                type="password"
                onChange={(e) => setRegisteredUser({ ...registeredUser, confirmPassword: e.target.value })}
                value={registeredUser.confirmPassword}
                autoComplete="current-password"
              />
            </div>
            {passwordError !== "" && (
              <span className="text-red-400">{passwordError}</span>
            )}
          </div>
          <p className="text-2xl font-bold m-0">Dati di fatturazione</p>
          <div className="sm:flex gap-5 mt-0">
            <InputField
              id="business-name"
              label="Ragione Sociale (opzionale)"
              onChange={(e) => setRegisteredUser({ ...registeredUser, businessName: e.target.value })}
              value={registeredUser.businessName}
              required={false}
            />
            <InputField
              id="vat-number"
              label="Partita IVA (opzionale)"
              onChange={(e) => setRegisteredUser({ ...registeredUser, vatNumber: e.target.value })}
              value={registeredUser.vatNumber}
              required={false}
            />
            <InputField
              id="fiscal-code"
              label="Codice fiscale"
              onChange={(e) => setRegisteredUser({ ...registeredUser, fiscalCode: e.target.value })}
              value={registeredUser.fiscalCode}
            />
          </div>
          <InputField
            id="iban"
            label="IBAN"
            onChange={(e) => setRegisteredUser({ ...registeredUser, iban: e.target.value })}
            value={registeredUser.iban}
            autoComplete="iban"
            required={true}
          />
          <span className="text-gray-400">
            L'IBAN sul quale riceverai i bonifici.
          </span>
          <div className="sm:flex">
            <div className="flex-1">
              <GoogleInputAddress address={registeredUser.address} setAddress={(e) => setRegisteredUser({ ...registeredUser, address: e.target.value })} onHandlePlaceChanged={(place) => {
                    const streetNumber = place.address_components.find((add) => add.types.some((t) => t === "street_number"))?.long_name
                    const address = place.address_components.find((add) => add.types.some((t) => t === "route"))?.long_name
                    const city = place.address_components.find((add) => add.types.some((t) => t === "administrative_area_level_3"))?.long_name
                    const province = place.address_components.find((add) => add.types.some((t) => t === "administrative_area_level_2"))?.short_name
                    const nation = place.address_components.find((add) => add.types.some((t) => t === "country"))?.long_name
                    const postalCode = place.address_components.find((add) => add.types.some((t) => t === "postal_code"))?.long_name
                    if(streetNumber === undefined || address === undefined || city === undefined || province === undefined || nation === undefined || postalCode === undefined){
                      setAddressError("Inserisci un indirizzo completo compreso di civico.")
                      return;
                    }
                    setAddressError("")
                    setRegisteredUser({ 
                      ...registeredUser,
                      civico:  streetNumber,
                      address:  address,
                      city:  city,
                      province:  province,
                      nation:  nation,
                      zipCode:  postalCode,
                    })
              }} />
              {addressError !== "" && (
              <span className="text-red-400">{addressError}</span>
            )}
            </div>
            <div className="sm:mx-3"></div>
            <InputField
              id="civico"
              label="Nr Civico"
              //disabled={true}
              value={registeredUser.civico}
              onChange={(e) => setRegisteredUser({ ...registeredUser, civico: e.target.value })}
              required={false}
              maxWidth={"sm:max-w-24"}
            />
            <div className="sm:mx-3"></div>
            <InputField
              id="phone"
              label="Cellulare"
              onChange={(e) => setRegisteredUser({ ...registeredUser, phone: e.target.value })}
              value={registeredUser.phone}
              required={false}
              maxWidth={"sm:max-w-48"}
            />
          </div>
          <div className="sm:flex gap-5">
            <InputField id="city" label="Città" 
              //disabled={true} 
              onChange={(e) => setRegisteredUser({ ...registeredUser, city: e.target.value })}
              value={registeredUser.city} />
            <InputField id="zip-code" label="Cap" 
              //disabled={true} 
              onChange={(e) => setRegisteredUser({ ...registeredUser, zipCode: e.target.value })}
              value={registeredUser.zipCode} />
            <InputField
              id="province"
              label="Provincia"
              value={registeredUser.province}
              //disabled={true}
              onChange={(e) => setRegisteredUser({ ...registeredUser, province: e.target.value })}
              placeholder={"es. Firenze, Pavia"}
            />
            <InputField
              id="nation"
              label="Nazione"
              value={registeredUser.nation}
              //disabled={true}
              onChange={(e) => setRegisteredUser({ ...registeredUser, nation: e.target.value })}
              placeholder={"es. Italia"}
            />
          </div>
          <div className="flex gap-3">
            <div className="flex items-center">
              <input
                required={true}
                onChange={(e) => setRegisteredUser({ ...registeredUser, privacy: e.target.checked })}
                checked={registeredUser.privacy}
                id="accept-privacy-policy"
                name="accept-privacy-policy"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label
                htmlFor="accept-privacy-policy"
                className="ml-3 block text-sm leading-6 text-gray-900"
              >
                Accetto il consenso alla{" "}
                <a
                  href="https://www.codfacile.com/policies/privacy-policy"
                  className="text-green-facile"
                >
                  privacy
                </a>
              </label>
            </div>
            <div className="flex items-center">
              <input
                required={true}
                onChange={(e) => setRegisteredUser({ ...registeredUser, termsAndConditions: e.target.checked })}
                checked={registeredUser.termsAndConditions}
                id="accept-terms-conditions"
                name="accept-terms-conditions"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label
                htmlFor="accept-terms-conditions"
                className="ml-3 block text-sm leading-6 text-gray-900"
              >
                Accetto i{" "}
                <a
                  href="https://codfacile.com/termini-e-condizioni-di-servizio"
                  className="text-green-facile"
                >
                  termini e condizioni
                </a>
              </label>
            </div>
          </div>

          <button
            disabled={isLoading}
            type="submit"
            className="flex w-full justify-center rounded-md bg-green-facile px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-facile focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-facile"
          >
            {isLoading ? <LoadingIcon color={"text-white"} /> : "Registrati"}
          </button>
          <span className="mt-3 leading-6 text-gray-500 text-sm">
            Hai già un account?{" "}
            <Link
              to={"/login"}
              className={"text-green-facile hover:text-green-facile"}
            >
              Accedi
            </Link>
          </span>
        </form>
      </div>
    </div>
  );
};
