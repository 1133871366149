import { Transition, Dialog, TransitionChild, DialogPanel } from '@headlessui/react';
import React from 'react';
import { LoadingIcon } from '../components/custom/LoadingIcon';



export const PopupLoading = ({ open, setOpen }) => {
    return (
        <Transition show={open}>
            <Dialog className="relative z-40 " onClose={setOpen}>
                <TransitionChild
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-50 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full min-w-24 items-center justify-center p-4 text-center sm:items-center sm:p-0">
                        <TransitionChild
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            {/* <DialogPanel className="max-h-96 relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"> */}
                            <DialogPanel
                                className={`max-h-128 md:max-h-256 max-w-7xl min-w-80 overflow-hidden relative transform rounded-lg bg-none flex justify-center transition-all sm:my-8 `}
                            >
                                <LoadingIcon color={"text-gray-900 h-7 w-7"} />
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};
