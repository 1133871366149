export const HandlingStatusEnum = Object.freeze({
  IN_LAVORAZIONE: {
    id: 0,
    name: "In Lavorazione"
  },
  SVINCOLO_NON_POSSIBILE: {
    id: 1,
    name: "Svincolo Non Possibile"
  },
  SVINCOLO_EFFETTUATO: {
    id: 2,
    name: "Svincolo Effettuato"
  },
  DA_LAVORARE: {
    id: 3,
    name: "Da Lavorare"
  },
});